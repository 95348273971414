import * as React from 'react';
import {Formik, Field, Form} from 'formik';
import {
    Button,
    Typography,
    Container,
    Grid,
    Autocomplete, IconButton
} from '@mui/material';
import {
    TextField
} from 'formik-material-ui';
import {TextField as MaterialTextField, Card} from '@mui/material';
import {withStyles} from '@mui/styles';
import {selectors as opportunitySelectors, actions as opportunityActions} from '../../../../Ducks/opportunity';
import {selectors as dashboardSelectors} from '../../../../Ducks/dashboard';
import {selectors as contactsSelectors, actions as contactsActions} from '../../../../Ducks/contacts';
import {selectors as accountSelectors} from '../../../../Ducks/account';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import LostModalV2 from '../Modals/LostModalV2';
import WonModal from '../Modals/WonModal';
import CvrModal from '../Modals/CvrModal';
import TransferModal from '../Modals/TransferModal';
import {useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectBox from '../../../Common/SelectBox';
import Swal from 'sweetalert2';
import {isPlainObject} from 'lodash';
import UnsuccessfulModal from '../Modals/UnsuccessfulModal';
import SwitchToInvoicesConfirmationModal from '../Modals/SwitchToInvoicesConfirmationModal';
import {LoadingButton} from '@mui/lab';
import {fetchGet} from "../../../../API/util";
import Tooltip from "@mui/material/Tooltip";
import {ContentCopy, Info} from "@mui/icons-material";
import {getProductCountry} from "../../../../API/products";
import {COUNTRY_DENMARK} from "../../../Utils/constants";
import {selectors as adminSelectors} from "../../../../Ducks/admin";

toast.configure();

const useStyles = _theme => ({
    noPadding: {
        padding: 0
    },
    container: {
        padding: 0,
        margin: 0
    }
});

class Deal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openLost: false,
            openWon: false,
            openCvr: false,
            openTransfer: false,
            openUnsuccessful: false,
            openSwitchToInvoicesConfirmation: false,
            selectedValue: null,
            formValues: {},
            cvrData: null,
            salesRep: "",
            users: [],
            pipelineID: "",
            pipelineStages: [],
            assignedOpportunity: false,
            errorMsg: [],
            bookkeeperHelpText: '',
            productCountry: null,
            delayInvoiceOptions: [
                {
                    label: '0 Days',
                    key: '0 Days',
                    id: '0 Days'
                },
                {
                    label: '14 Days',
                    key: '14 Days',
                    id: '14 Days'
                },
                {
                    label: '30 Days',
                    key: '30 Days',
                    id: '30 Days'
                },
                {
                    label: '60 Days',
                    key: '60 Days',
                    id: '60 Days'
                },

            ]
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {

        const {
            opportunity,
            getFoodConsiderations,
            getSalesReps,
            foodConsiderations,
        } = this.props;

        getSalesReps();
        getFoodConsiderations();

        if (opportunity) {
            this.populateForm(opportunity, foodConsiderations);

            if (opportunity.main.product) {
                getProductCountry(opportunity.main.product).then((res) => {
                    if (res.country) {
                        this.setState({'productCountry': res.country});
                    }
                });
            }
        }
    }

    componentDidUpdate(prevProps) {

        const {cvr, salesReps, pipelineId, opportunity, closedWon, foodConsiderations} = this.props;

        if (prevProps.cvr !== cvr) {
            this.parseCVR(cvr);
        }

        if (prevProps.salesReps !== salesReps) {
            let users = salesReps.main.filter(item => item.user_deactivated === null && !item.user_lastname.includes("WRONG")).map((item, index) => {
                item.label = item.user_firstname + " " + item.user_lastname;
                item.key = {index}
                return item;
            });
            this.setState({users});
        }

        if (prevProps.pipelineId !== pipelineId && (this.state.salesRep !== "")) {
            if (pipelineId.length) {
                let pipeline_id = pipelineId[0].pipeline_id;
                this.setState({pipelineID: pipeline_id}, () => {
                    this.handleModal('openTransfer');
                })
            } else {
                // show toast
                toast.error('Cannot show stages as it has no pipeline id', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 2000
                });
            }
        }

        if (prevProps.opportunity !== opportunity) {
            opportunity && this.populateForm(opportunity, foodConsiderations);
        }

        if (prevProps.closedWon !== closedWon) {
            this.setState({openSwitchToInvoicesConfirmation: true});
        }
    }

    checkOpportunityAssignment = (opportunity, pipelines) => {
        if (pipelines !== null) {
            const pipeline_stage_id = opportunity.main.pipeline_stage_id;
            const pipelines_arr = pipelines.main.pipeline_stages;
            if (pipelines_arr.includes(pipeline_stage_id)) {
                this.setState({assignedOpportunity: true});
            } else {
                this.setState({assignedOpportunity: false});
            }
        }
    }

    populateForm = (opportunity, foodConsiderations) => {
        this.formRef.current.setFieldValue('opportunity_cvr', opportunity.main.opportunity_cvr);
        this.formRef.current.setFieldValue('company_name', opportunity.related_objects.persons[opportunity.related_objects.jobs[opportunity.main.job_id].person_id].company_name);
        this.formRef.current.setFieldValue('opportunity_address_1', opportunity.main.opportunity_address_1);
        this.formRef.current.setFieldValue('opportunity_address_2', opportunity.main.opportunity_address_2);
        this.formRef.current.setFieldValue('opportunity_address_postal_code', opportunity.main.opportunity_address_postal_code);
        this.formRef.current.setFieldValue('opportunity_address_city', opportunity.main.opportunity_address_city);
        this.formRef.current.setFieldValue('opportunity_address_country', opportunity.main.opportunity_address_country);
        this.formRef.current.setFieldValue('opportunity_bookkeeper_email', opportunity.main.opportunity_bookkeeper_email);
        this.formRef.current.setFieldValue('opportunity_ean', opportunity.main.opportunity_ean);
        this.formRef.current.setFieldValue('opportunity_po_number', opportunity.main.opportunity_po_number);
        this.formRef.current.setFieldValue('opportunity_delayed_invoice', opportunity.main.opportunity_delayed_invoice);
        this.formRef.current.setFieldValue('opportunity_price', opportunity.main.opportunity_price);
        this.formRef.current.setFieldValue('opportunity_split_payment', opportunity.main.opportunity_split_payment);
        this.formRef.current.setFieldValue('opportunity_food_considerations', opportunity.main.opportunity_food_considerations);
        this.formRef.current.setFieldValue('person_food_considerations_1', opportunity.main.opportunity_food_considerations_1 == "" ? foodConsiderations.type1_food.find(item => item.id == 17).id : opportunity.main.opportunity_food_considerations_1);

        this.formRef.current.setFieldValue('person_food_considerations_2', opportunity.main.opportunity_food_considerations_2?.length == 0 ? [foodConsiderations?.type2_food.find(item => item.id == 18)?.id] : opportunity.main.opportunity_food_considerations_2);
        this.formRef.current.setFieldValue('person_food_considerations_other', opportunity.main.opportunity_food_considerations_other);
        this.formRef.current.setFieldValue('opportunity_special_conditions', opportunity.main.opportunity_special_conditions);

        if (opportunity?.main?.opportunity_cvr) {
            this.fetchBookkeeperByCvr(opportunity.main.opportunity_cvr);
        }
    }

    handleModal = (modal) => {
        this.setState({[modal]: true});
    };

    handleClose = () => {
        this.setState({
            openLost: false,
            openWon: false,
            openCvr: false,
            openTransfer: false,
            openUnsuccessful: false,
            openSwitchToInvoicesConfirmation: false
        });
    };

    handleBlur = (e) => {
        const {opportunity} = this.props;
        if (opportunity.main[e.target.name] !== e.target.value) {
            let formData = {
                [e.target.name]: e.target.value
            }
            this.props.patchOpportunityData({formData, id: this.props.params.id});
        }
    }

    handleChange = (e) => {
        let arr = e.target.value;
        this.formRef.current.setFieldValue('person_food_considerations_2', arr);

        if (arr.includes(18)) {
            let filterd = arr.filter(item => item !== 18);
            this.formRef.current.setFieldValue('person_food_considerations_2', filterd);

        } else if (!e.target.value.length) {
            this.formRef.current.setFieldValue('person_food_considerations_2', [18]);
        }
    }

    handleCVR = (e) => {
        const {opportunity} = this.props;
        if (opportunity.main[e.target.name] !== e.target.value) {
            let formData = {
                [e.target.name]: e.target.value
            }
            this.props.patchOpportunityData({formData, id: this.props.params.id});
            if (e.target.value !== "" && !e.target.value.includes("-")) {
                this.props.searchCvr(e.target.value);
                //fetch bookkeeper by cvr
                this.fetchBookkeeperByCvr(e.target.value);
            }
        }
    }

    getCountryByCode = (code) => {
        if (code === "DK") {
            return "Denmark"
        }
        return code;
    }

    parseCVR = (cvr) => {
        if (isPlainObject(cvr)) {
            let cvr_data = [];
            let data_vrvirksomhed = cvr['hits']['hits'][0]['_source']['Vrvirksomhed'];
            if (data_vrvirksomhed['navne'].length && data_vrvirksomhed['beliggenhedsadresse'].length) {
                let company_name_data = data_vrvirksomhed['navne'][data_vrvirksomhed['navne'].length - 1];
                let location_address_data = data_vrvirksomhed['beliggenhedsadresse'][data_vrvirksomhed['beliggenhedsadresse'].length - 1];
                cvr_data['company_name'] = company_name_data['navn'];
                cvr_data['opportunity_address_country'] = this.getCountryByCode(location_address_data['landekode']);
                cvr_data['opportunity_address_city'] = location_address_data['kommune'].kommuneNavn; // => City Name
                cvr_data['opportunity_address_postal_code'] = location_address_data['postnummer']; // => ZipCode
                let floor = location_address_data['etage'] === null ? "" : location_address_data['etage'];
                floor = floor === "" ? "" : `, ${floor}`;
                cvr_data['opportunity_address_1'] = location_address_data['vejnavn'] + " " + location_address_data['husnummerFra'] + floor;

                this.setState({
                    cvrData: cvr_data
                }, () => {
                    this.handleModal('openCvr')
                })
            }
        } else {
            Swal.fire({
                title: "Not Found",
                text: "No matching record found against the entered CVR",
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Ok",
            }).then(_result => {
            });
        }
    }

    saveInformation = () => {
        let formData = this.formRef.current.values;

        if(formData.person_food_considerations_1 == "" || formData.person_food_considerations_2.length == 0)
        {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Food consideration can not be empty",
        });
        }


        else{
        this.props.postOpportunityData({ formData, id: this.props.params.id });
        }
    }


    handleTransferOpportunity = (_event, value) => {
        if (value !== null) {
            this.props.getPipelineId(value.user_id);
            this.setState({salesRep: value.user_id});
        } else {
            this.setState({salesRep: ""});
        }
    }

    setDateFormate = (date) => {
        let d = new Date(date)
        var MyDateString = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
        return MyDateString;
    }

    formattedDate = (date) => {
        if (date === "0000-00-00 00:00:00" || date === null || date === "") {
            return this.setDateFormate(new Date(new Date().getTime() + (8 * 24 * 60 * 60 * 1000)));
        } else {
            return this.setDateFormate(date)
        }
    }

    fetchBookkeeperByCvr = (cvrNumber) => {
        const api = `/bookkeeper/${cvrNumber}`;

        fetchGet(api).then((response) => {
            if (response) {
                let bookkeeperHelpText = '';

                if (response.bookkeeper_email) {
                    bookkeeperHelpText += 'Bookkeeper email: ' + response.bookkeeper_email + '\n';
                }

                if (response.ean) {
                    bookkeeperHelpText += 'EAN: ' + response.ean + '\n';
                }

                if (response.payment_terms) {
                    bookkeeperHelpText += 'Payment terms: ' + response.payment_terms + '\n';
                }

                if (response.po_number) {
                    bookkeeperHelpText += response.po_number + '\n';
                }

                this.setState({
                    bookkeeperHelpText: bookkeeperHelpText
                });
            }
        })
    }

    handleBookkeeperHelpTextClick = () => {
        const {bookkeeperHelpText} = this.state;
        let _bookkeeperText = bookkeeperHelpText.split('\n');

        if (_bookkeeperText?.length > 0) {
            let bookkeeper_email = _bookkeeperText[0].indexOf('Bookkeeper email: ');

            if (bookkeeper_email !== -1) {
                bookkeeper_email = _bookkeeperText[0].replace('Bookkeeper email: ', '');
                this.formRef.current.setFieldValue('opportunity_bookkeeper_email', bookkeeper_email);
            }

            let ean = _bookkeeperText[0].indexOf('EAN: ');

            if (ean !== -1) {
                ean = _bookkeeperText[0].replace('EAN: ', '');
                this.formRef.current.setFieldValue('opportunity_ean', ean);
            } else {
                if (_bookkeeperText?.length > 1) {
                    ean = _bookkeeperText[1].indexOf('EAN: ');
                    if (ean !== -1) {
                        ean = _bookkeeperText[1].replace('EAN: ', '');
                        this.formRef.current.setFieldValue('opportunity_ean', ean);
                    } else {
                        this.formRef.current.setFieldValue('opportunity_ean', '');
                    }
                } else {
                    this.formRef.current.setFieldValue('opportunity_ean', '');
                }
            }
        }
    }

    render() {
        const {
            classes,
            opportunity,
            user,
            foodConsiderations,
            postClosedWon_loading,
            financeEnabled
        } = this.props;
        const {users, delayInvoiceOptions, bookkeeperHelpText, productCountry} = this.state;
        const capability = user && user.user_details.capabilities.filter((item) => item.capability_title === "can_close_lost_with_unsuccessful_campaign");

        return (
            <React.Fragment>
                <div className="deals-container">
                    <div>
                        <Container className={classes.container}>
                            <Typography component="h1" variant="h6" align="left" color="textPrimary" gutterBottom>
                                {opportunity ? "Company info for invoice" : ""}
                            </Typography>
                        </Container>
                    </div>
                    <Card style={{padding: '12px'}}>
                        {
                            opportunity ? (
                                <Formik
                                    innerRef={this.formRef}
                                    initialValues={{
                                        opportunity_cvr: "",
                                        company_name: "",
                                        opportunity_address_1: "",
                                        opportunity_address_2: "",
                                        opportunity_address_postal_code: "",
                                        opportunity_address_city: "",
                                        opportunity_address_country: "",
                                        opportunity_bookkeeper_email: "",
                                        opportunity_ean: "",
                                        opportunity_po_number: "",
                                        opportunity_delayed_invoice: "",
                                        opportunity_price: "",
                                        opportunity_split_payment: "",
                                        opportunity_food_considerations: "",
                                        person_food_considerations_1: "",
                                        person_food_considerations_2: [],
                                        person_food_considerations_other: "",
                                        opportunity_special_conditions: "",
                                    }}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.opportunity_ean && !values.opportunity_bookkeeper_email) {
                                            errors.opportunity_ean = 'Required';
                                            errors.opportunity_bookkeeper_email = 'Required';
                                        }
                                        if (values.opportunity_bookkeeper_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.opportunity_bookkeeper_email)) {
                                            errors.opportunity_bookkeeper_email = 'Invalid email address';
                                        }
                                        if (values.opportunity_ean && values.opportunity_ean.length < 13) {
                                            errors.opportunity_ean = 'EAN should be at least 13 characters';
                                        }

                                        if (values.opportunity_ean && (values.opportunity_ean.length > 20 || /[!@$^%+=#&*():;.<>?,"']/i.test(values.opportunity_ean))) {
                                            errors.opportunity_ean = 'Invalid EAN';
                                        }

                                        if (!values.opportunity_cvr) {
                                            errors.opportunity_cvr = 'Required';
                                        }
                                        if (!values.company_name) {
                                            errors.company_name = 'Required';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, {setSubmitting}) => {
                                        this.setState({errorMsg: []});

                                        const {opportunity} = this.props;
                                        const selectedEvents = opportunity.related_objects.events_to_opportunities || {};
                                        const productTypes = opportunity.related_objects.product_types;
                                        const count = Object.keys(selectedEvents).length;
                                        const totalRequiredEvents = (productTypes && productTypes[Object.keys(productTypes)[0]].product_type_events_required) || 1;
                                        //handle primary job condition
                                        const personID = opportunity.related_objects.jobs[opportunity.main.job_id].person_id;
                                        const personObj = opportunity.related_objects.persons[personID];
                                        let errors = [];

                                        setSubmitting(false);
                                        if (count >= totalRequiredEvents && personObj.email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(personObj.email)) {
                                            this.setState({formValues: values}, () => {
                                                this.handleModal('openWon');
                                            })
                                        } else {
                                            if (count < totalRequiredEvents) {
                                                let msg = `Error: You must select at least ${totalRequiredEvents} event(s) to close this opportunity.`;
                                                errors.push(msg);
                                                this.setState({errorMsg: errors})
                                            }
                                            if (!personObj.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(personObj.email)) {
                                                let msg = `Error: The primary job of the person must have a valid email address.`;
                                                errors.push(msg);
                                                this.setState({errorMsg: errors})
                                            }
                                        }
                                    }}
                                    render={({submitForm, isSubmitting, values, setFieldValue}) => (
                                        <Form>
                                            <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        required
                                                        sx={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            maxWidth: '400px',
                                                            margin: 'auto'
                                                        }}
                                                        classes={{input: classes.noPadding}}
                                                        name="opportunity_cvr"
                                                        type="text"
                                                        label="CVR"
                                                        variant="outlined"
                                                        margin="normal"
                                                        component={TextField}
                                                        onBlur={this.handleCVR}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                    <div className='info-message'>
                                                        <p>
                                                            SE Only - CVR Number format is xxxxxxx-xxxx
                                                        </p>
                                                        <p>
                                                            Please enter -1 if client will pay himself instead company's
                                                            account
                                                        </p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        required
                                                        name="company_name"
                                                        type="text"
                                                        label="Company Name"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_1"
                                                        type="text"
                                                        label="Address 1"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_2"
                                                        type="text"
                                                        label="Address 2"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_postal_code"
                                                        type="text"
                                                        label="Zip Code"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_city"
                                                        type="text"
                                                        label="City"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_country"
                                                        type="text"
                                                        label="Country"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{marginBottom: '15px'}} container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6" align="left"
                                                                color="textPrimary" gutterBottom>
                                                        Bookkeeping info
                                                        {
                                                            bookkeeperHelpText && <>
                                                                <Tooltip
                                                                    title={
                                                                        <Grid container>
                                                                            <Grid item xs={12}
                                                                                  style={{whiteSpace: 'pre-line'}}>{bookkeeperHelpText}
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                    arrow
                                                                    placement='top'>
                                                                    <IconButton style={{padding: '2px'}}>
                                                                        <Info
                                                                            color={bookkeeperHelpText ? "success" : "default"}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <IconButton style={{padding: '2px'}}
                                                                            onClick={this.handleBookkeeperHelpTextClick}>
                                                                    <ContentCopy/>
                                                                </IconButton>
                                                            </>
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_bookkeeper_email"
                                                        type="text"
                                                        label="Bookkeeper e-mail"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_ean"
                                                        type="text"
                                                        label="EAN"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_po_number"
                                                        type="text"
                                                        label="PO Number"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{marginBottom: '15px'}} container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6" align="left"
                                                                color="textPrimary">
                                                        Payment Terms
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        disablePortal
                                                        size='small'
                                                        id="combo-box"
                                                        options={delayInvoiceOptions}
                                                        sx={{width: '100%'}}
                                                        onChange={(e, value) => setFieldValue("opportunity_delayed_invoice", value.key)}
                                                        onBlur={(e) => {
                                                            let formData = {
                                                                opportunity_delayed_invoice: e.target.value
                                                            }
                                                            this.props.patchOpportunityData({
                                                                formData,
                                                                id: this.props.params.id
                                                            });
                                                        }}
                                                        value={values.opportunity_delayed_invoice}
                                                        renderInput={(params) => <MaterialTextField {...params}
                                                                                                    label="Delay Invoice"/>}
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name="opportunity_price"
                                                        type="text"
                                                        label="Price"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        size="small"
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                                <Grid item xs={5} sx={{display: 'flex', alignItems: 'center'}}>
                                                    <label>
                                                        <Field type="checkbox" name="opportunity_split_payment"
                                                               disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}/>
                                                        50% now, 50% after 2nd meeting
                                                    </label>
                                                </Grid>
                                            </Grid>

                                            <Grid sx={{marginBottom: '15px'}} container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6" align="left"
                                                                color="textPrimary">
                                                        Extra Information
                                                    </Typography>
                                                    <Typography component="h6" variant="h6" align="left"
                                                                color="textPrimary"
                                                                sx={{fontSize: '16px', margin: '10px 0 5px'}}>
                                                        Food Consideration
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? (
                                                            <>
                                                                <div className="food-consideration-section">
                                                                    <SelectBox
                                                                        multiple={false}
                                                                        name="person_food_considerations_1"
                                                                        type="type1_food"
                                                                        label="Select Type"
                                                                        labelId="select-type"
                                                                        options={foodConsiderations ? foodConsiderations : []}
                                                                    />
                                                                    <SelectBox
                                                                        multiple={true}
                                                                        name="person_food_considerations_2"
                                                                        type="type2_food"
                                                                        label="Select Allergy"
                                                                        labelId="select-allergy"
                                                                        onChange={this.handleChange}
                                                                        options={foodConsiderations ? foodConsiderations : []}
                                                                    />
                                                                </div>
                                                                {
                                                                    this.formRef.current && this.formRef.current.values && this.formRef.current.values.person_food_considerations_2 && this.formRef.current.values.person_food_considerations_2.filter((item) => item === 16).length ? (
                                                                        <Field
                                                                            component="textarea"
                                                                            fullWidth
                                                                            rows="5"
                                                                            size="large"
                                                                            name="person_food_considerations_other"
                                                                            style={{
                                                                                width: '100%',
                                                                                padding: '10px',
                                                                                fontSize: '16px',
                                                                                borderColor: "#c4c4c4",
                                                                                borderRadius: '5px'
                                                                            }}
                                                                            placeholder="Please write other allergies"
                                                                        />
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <Field
                                                                type="text"
                                                                label="Food Consideration"
                                                                name="opportunity_food_considerations"
                                                                multiline
                                                                rows="5"
                                                                variant="outlined"
                                                                margin="normal"
                                                                fullWidth
                                                                component={TextField}
                                                                onBlur={this.handleBlur}
                                                                disabled={true}
                                                            />
                                                        )
                                                    }

                                                    <Field
                                                        type="text"
                                                        label="Special conditions"
                                                        name="opportunity_special_conditions"
                                                        multiline
                                                        rows="4"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={this.handleBlur}
                                                        disabled={opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? false : true}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {
                                                opportunity && opportunity.main.self_opportunity && opportunity.main.opportunity_status === "open" ? (
                                                    <>
                                                        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                            <Grid item xs={12}>
                                                                <div>
                                                                    {
                                                                        this.state.errorMsg.map((msg, index) => {
                                                                            return (
                                                                                <p key={index} style={{
                                                                                    textAlign: 'center',
                                                                                    color: 'red',
                                                                                    margin: 0
                                                                                }}>{msg}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                            <Grid item xs={12} sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    disabled={isSubmitting}
                                                                    onClick={() => this.handleModal('openLost')}
                                                                >
                                                                    Close as lost
                                                                </Button>
                                                                {
                                                                    opportunity && capability.length > 0 ? (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="warning"
                                                                            disabled={isSubmitting}
                                                                            onClick={() => this.handleModal('openUnsuccessful')}
                                                                        >
                                                                            Unsuccessful campaign
                                                                        </Button>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                                <LoadingButton
                                                                    variant="contained"
                                                                    color="success"
                                                                    disabled={isSubmitting}
                                                                    onClick={submitForm}
                                                                    loading={postClosedWon_loading}
                                                                >
                                                                    Close as won
                                                                </LoadingButton>
                                                                <LoadingButton
                                                                    loading={this.props.oppSaveInfoLoader}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    disabled={isSubmitting}
                                                                    onClick={this.saveInformation}
                                                                >
                                                                    Save Information
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography component="h1" variant="h6" align="center"
                                                                            color="textPrimary">
                                                                    Transfer opportunity to another user
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}
                                                                  sx={{display: 'flex', justifyContent: 'center'}}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    options={users}
                                                                    sx={{width: 300}}
                                                                    onChange={this.handleTransferOpportunity}
                                                                    renderInput={(params) =>
                                                                        <MaterialTextField {...params}
                                                                                           label="Select a user"/>}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>

                                                ) : (
                                                    <></>
                                                )
                                            }

                                            <LostModalV2
                                                open={this.state.openLost}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formDataValues={this.formRef.current === null ? null : this.formRef.current.values}
                                            />

                                            <UnsuccessfulModal
                                                open={this.state.openUnsuccessful}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formDataValues={this.formRef.current === null ? null : this.formRef.current.values}
                                            />

                                            <WonModal
                                                open={this.state.openWon}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formData={this.state.formValues}
                                            />

                                            <CvrModal
                                                open={this.state.openCvr}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formData={this.formRef.current === null ? null : this.formRef.current.values}
                                                cvrData={this.state.cvrData}
                                                formReference={this.formRef}
                                            />

                                            <TransferModal
                                                open={this.state.openTransfer}
                                                onClose={this.handleClose}
                                                pipelineID={this.state.pipelineID}
                                                emptySalesRep={() => this.setState({salesRep: ""})}
                                                formDataValues={this.formRef.current === null ? null : this.formRef.current.values}
                                            />

                                            {financeEnabled && productCountry?.country_name === COUNTRY_DENMARK &&
                                                <SwitchToInvoicesConfirmationModal
                                                    open={this.state.openSwitchToInvoicesConfirmation}
                                                    onClose={this.handleClose}
                                                />
                                            }

                                        </Form>
                                    )}
                                />
                            ) : (
                                <></>
                            )
                        }
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    partialOpportunityData: opportunitySelectors.partialOpportunityData(state),
    salesReps: opportunitySelectors.salesReps(state),
    cvr: opportunitySelectors.cvr(state),
    pipelineId: opportunitySelectors.pipelineId(state),
    stages: opportunitySelectors.stages(state),
    pipelines: dashboardSelectors.pipelines(state),
    user: accountSelectors.user(state),
    foodConsiderations: contactsSelectors.foodConsiderations(state),
    postClosedWon_loading: opportunitySelectors.postClosedWonLoading(state),
    closedWon: opportunitySelectors.closedWon(state),
    opportunity_loading: opportunitySelectors.opportunity_loading(state),
    oppSaveInfoLoader: opportunitySelectors.oppSaveInfoLoader(state),
    financeEnabled: adminSelectors.financeEnabled(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        patchOpportunityData: opportunityActions.patchOpportunityData,
        postOpportunityData: opportunityActions.postOpportunityData,
        searchCvr: opportunityActions.searchCvr,
        getSalesReps: opportunityActions.getSalesReps,
        getPipelineId: opportunityActions.getPipelineId,
        getStages: opportunityActions.getStages,
        getFoodConsiderations: contactsActions.getFoodConsiderations
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <Deal {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
