import {
    createActions, asyncInitialState, asyncOnRequest,
    asyncOnSuccess, asyncOnError, asyncSelectors
} from './utils';
import Swal from 'sweetalert2';

export const {types, actions} = createActions(
    {
        asyncs: {
            getOpportunityDetails: (data) => data,
            cacheOpportunity: (data) => data,
            resetCalendarLoader: (data) => data,
            getAllEvents: (data) => data,
            clearEvents: (data) => data,
            updateEvent: (data) => data,
            removeOpportunityFromCache: (data) => data,
            updateOpportunityStatus: (data) => data,
            clearOpportunityCache: (data) => data,
            getEmployees: (data) => data,
            getStages: (data) => data,
            getUserPipelineStages: (data) => data,
            getHistory: (data) => data,
            getJobs: (data) => data,
            getOpportunities: (data) => data,
            getProducts: (data) => data,
            getFilteredProducts: (data) => data,
            getProductOverview: (data) => data,
            getMails: (data) => data,
            getSmsTemplates: (data) => data,
            getLostReasons: (data) => data,
            getSalesReps: (data) => data,
            getPipelineId: (data) => data,
            patchOpportunityData: (data) => data,
            postOpportunityData: (data) => data,
            postClosedLostReasons: (data) => data,
            postUnsuccessfulCampaign: (data) => data,
            postClosedWon: (data) => data,
            postNewJob: (data) => data,
            searchCvr: (data) => data,
            checkEvent: (data) => data,
            unCheckEvent: (data) => data,
            switchProduct: (data) => data,
            applyEmailTemplate: (data) => data,
            applySmsTemplate: (data) => data,
            sendEmail: (data) => data,
            sendActionEmail: (data) => data,
            sendSms: (data) => data,
            addEventLog: (data) => data,
            addCalendarEvent: (data) => data,
            editCalendarEvent: (data) => data,
            updateExistingJob: (data) => data,
            updateStage: (data) => data,
            searchCompanies: (data) => data,
            getCalendar: (data) => data,
            updateDivisionPhone: (data) => data,
            updateContact: (data) => data,
            updateJobPhone: (data) => data,
            opportunityActiveCallAction: (data) => data,
            postOpportunityAction: (data) => data,
            getPersons: (data) => data,
            getUserPipelineContent: (data) => data,
            getHistoryNoteTypes: (data) => data,
            postPermissions: (data) => data,
            closePermissionModal: (data) => data,
            sendTestEmail: (data) => data,
            togglePin: (data) => data,
            latestPlannedAction: (data) => data,
        },
    },
    "oppurtunity"
);

let initialState = asyncInitialState({
    opportunity: null,
    events: null,
    getAllEvenets_inProgress: false,
    calendarIsLoading: false,
    opportunities_cache: {},
    opportunity_loading: false,
    employees: null,
    stages: null,
    userPipelineStages: null,
    history: null,
    opportunities: null,
    jobs: null,
    products: null,
    filteredProducts: null,
    productOverview: null,
    mails: null,
    smsTemplates: null,
    lostReasons: null,
    partialOpportunityData: null,
    opportunityData: null,
    searchCvr: null,
    salesReps: null,
    closedLostReasons: null,
    closedWon: null,
    pipelineId: null,
    errorMessage: "",
    success: false,
    getOpportunityDetails_loading: false,
    salesReps_loading: false,
    selectedEvents: {},
    emailTemplate: null,
    searchedCompanies: [],
    calendar: null,
    sentSms: null,
    sentEmail: null,
    postOpportunityActionRes: null,
    opportunityActiveCallActionResponse: null,
    persons: null,
    userPipelineContent: null,
    historyNoteTypes: null,
    permissionModal: false,
    sentTestEmail: null,
    togglePin: false,
    plannedActionResponse: null,
    latestPlannedActionResponse: null,
    postClosedWon_loading: false,
    updateJobLoader: false,
    oppInfoSaveLoader: false
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.getOpportunityDetails:
            return {
                ...asyncOnRequest(state, action),
                data: {...state.data, opportunity_loading: true, permissionModal: false}
            };
        case types.cacheOpportunity:
            return {data: {...state.data, opportunities_cache: {...state.data.opportunities_cache, ...action.data}}};
        case types.removeOpportunityFromCache:
            return {
                data: {
                    ...state.data,
                    opportunities_cache: {...state.data.opportunities_cache, [action.data.id]: undefined}
                }
            };
        case types.clearOpportunityCache:
            return {data: {...state.data, opportunities_cache: {}}};
        case types.updateOpportunityStatus:
            const {opportunity_status} = action.data;
            const {opportunity} = state.data;
            return {
                ...state,
                data: {...state.data, opportunity: {...opportunity, main: {...opportunity.main, opportunity_status}}}
            };
        case types.getAllEvents:
            return {
                ...asyncOnRequest(state, action),
                data: {
                    ...state.data,
                    getAllEvenets_inProgress: true,
                    getAllEvents_loading: action.data?.showLoader === undefined || state.data.getAllEvents_loading,
                    permissionModal: false,
                },
            };
        case types.clearEvents:
            return {...state, data: {...state.data, events: []}};
        case types.updateEvent:
        case types.getEmployees:
        case types.getStages:
        case types.getUserPipelineStages:
        case types.getHistory:
        case types.getJobs:
        case types.getOpportunities:
            return {...asyncOnRequest(state, action), opportunity: null};
        case types.getProducts:
        case types.getFilteredProducts:
        case types.getProductOverview:
        case types.getMails:
        case types.getSmsTemplates:
        case types.getLostReasons:
        case types.getSalesReps:
            return {...asyncOnRequest(state, action), salesReps_loading: true};
        case types.getPipelineId:
        case types.patchOpportunityData:
        case types.postOpportunityData:
            // opportunity_loading is set to false when fetching opportunity e.g. in post action callback
            return {
                ...asyncOnRequest(state, action),
                data: {...state.data, opportunity_loading: action.data?.showLoader, oppInfoSaveLoader: true}
            };
        case types.postClosedLostReasons:
            return {
                ...asyncOnRequest(state, action),
                data: {...state.data, opportunity_loading: action.data?.showLoader}
            }
        case types.updateExistingJob:
            return {...asyncOnRequest(state, action), data: {...state.data, updateJobLoader: true}}
        case types.postUnsuccessfulCampaign:
        case types.postNewJob:
        case types.switchProduct:
        case types.applyEmailTemplate:
        case types.applySmsTemplate:
        case types.sendEmail:
        case types.sendActionEmail:
        case types.sendSms:
        case types.addEventLog:
        case types.searchCvr:
        case types.updateStage:
        case types.updateContact:
        case types.updateJobPhone:
        case types.searchCompanies:
            return asyncOnRequest(state, action);
        case types.getCalendar:
            return {...state, data: {...state.data, calendarIsLoading: true}}
        case types.resetCalendarLoader:
            return {...state, data: {...state.data, calendarIsLoading: false}};
        case types.editCalendarEvent:
        case types.opportunityActiveCallAction:
        case types.postOpportunityAction:
        case types.updateDivisionPhone:
        case types.getPersons:
        case types.getUserPipelineContent:
        case types.getHistoryNoteTypes:
        case types.postPermissions:
        case types.sendTestEmail:
        case types.togglePin:
        case types.latestPlannedAction:
            return asyncOnRequest(state, action);
        case types.postClosedWon:
            return {...state, data: {...state.data, postClosedWon_loading: true}};
        case types.checkEvent:
            return {
                ...asyncOnRequest(state, action),
                selectedEvents: {...state.selectedEvents, [action.eventId]: true}
            };
        case types.addCalendarEvent:
            return {
                ...asyncOnRequest(state, action),
                getCalendar_loading: true,
            };
        case types.saga.getAllEvents.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    events: action.payload.data,
                    getAllEvents_loading: false,
                    getAllEvenets_inProgress: false,
                    errorMessage: "",
                };
            });
        case types.saga.getAllEvents.failure:
            return {
                ...asyncOnError(state, action),
                data: {
                    ...state.data,
                    events: [],
                    getAllEvents_loading: false,
                    getAllEvenets_inProgress: false,
                },
            };
        case types.saga.updateEvent.success:
        case types.unCheckEvent:
            return {
                ...asyncOnRequest(state, action),
                selectedEvents: {...state.selectedEvents, [action.eventId]: false}
            };
        case types.saga.getOpportunityDetails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    opportunity: action.payload.data,
                    plannedActionResponse: action.payload.data.related_objects.opportunity_actions
                        ? Object.values(action.payload.data.related_objects.opportunity_actions)[0]
                        : null,
                    opportunity_loading: false,
                    errorMessage: "",
                };
            });
        case types.saga.getEmployees.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    employees: action.payload.data,
                    errorMessage: "",
                };
            });
        case types.saga.getStages.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    stages: action.payload.data,
                    errorMessage: "",
                };
            });
        case types.saga.getUserPipelineStages.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    userPipelineStages: action.payload.data.main,
                    errorMessage: "",
                };
            });
        case types.saga.getHistory.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    history: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.getJobs.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    jobs: action.payload.data,
                    errorMessage: "",
                };
            });
        case types.saga.getProducts.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    products: action.payload.data.main,
                    errorMessage: "",
                };
            });
        case types.saga.getFilteredProducts.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    filteredProducts: action.payload.data.main,
                    errorMessage: "",
                };
            });
        case types.saga.getProductOverview.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    productOverview: action.payload.data,
                    errorMessage: "",
                };
            });
        case types.saga.getMails.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    mails: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.getSmsTemplates.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    smsTemplates: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.getOpportunities.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    opportunities: action.payload.data,
                    opportunity: null,
                    errorMessage: "",
                };
            });
        case types.saga.getLostReasons.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    lostReasons: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.getSalesReps.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    salesReps_loading: false,
                    salesReps: action.payload.data,
                    errorMessage: "",
                };
            });
        case types.saga.getPipelineId.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    pipelineId: action.payload.data.main,
                    errorMessage: "",
                };
            });
        case types.saga.patchOpportunityData.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    partialOpportunityData: action.payload,
                    oppInfoSaveLoader: false,
                    errorMessage: "",
                };
            });
        case types.saga.postOpportunityData.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    opportunityData: action.payload,
                    opportunity_loading: false,
                    oppInfoSaveLoader: false,
                    errorMessage: "",
                };
            });
        case types.saga.postClosedLostReasons.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    closedLostReasons: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.postUnsuccessfulCampaign.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    unsuccessfulCampaign: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.postClosedWon.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    closedWon: action.payload,
                    postClosedWon_loading: false,
                    errorMessage: "",
                    opportunity: {
                        ...data.opportunity,
                        main: {...data.opportunity.main, opportunity_status: "closed:won"}
                    }
                };
            });
        case types.saga.postNewJob.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    postedJob: action.payload,
                    updateJobLoader: false,
                    errorMessage: "",
                };
            });
        case types.saga.searchCvr.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    cvr: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.checkEvent.success:
            return asyncOnSuccess(state, action, (data, _action) => {
                return {
                    ...data,
                    errorMessage: "",
                    opportunity: {
                        ...data.opportunity,
                        main: {...data.opportunity.main, events_to_opportunity: Object.keys(action.payload.events)},
                        related_objects: {
                            ...data.opportunity.related_objects,
                            events_to_opportunities: action.payload.events
                        }
                    }
                };
            });
        case types.saga.unCheckEvent.success:
            return asyncOnSuccess(state, action, (data, _action) => {
                return {
                    ...data,
                    errorMessage: "",
                    opportunity: {
                        ...data.opportunity,
                        main: {...data.opportunity.main, events_to_opportunity: Object.keys(action.payload.events)},
                        related_objects: {
                            ...data.opportunity.related_objects,
                            events_to_opportunities: action.payload.events
                        }
                    }
                };
            });
        case types.saga.switchProduct.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    switchedProduct: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.applyEmailTemplate.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    emailTemplate: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.applySmsTemplate.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    smsTemplate: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.sendEmail.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    sentEmail: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.sendActionEmail.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    sentEmail: action.payload,
                    calendarIsLoading: false,
                    errorMessage: "",
                };
            });
        case types.saga.sendSms.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    sentSms: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.addEventLog.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    addEventLog: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.addCalendarEvent.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    plannedActionResponse: action.payload.data.main,
                    getCalendar_loading: true,
                    errorMessage: "",
                };
            });
        case types.saga.editCalendarEvent.success:
            return asyncOnSuccess(state, action, (data, _action) => {
                return {
                    ...data,
                    errorMessage: "",
                };
            });
        case types.saga.updateExistingJob.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    existingJob: action.payload,
                    updateJobLoader: false,
                    errorMessage: "",
                };
            });
        case types.saga.updateStage.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedStage: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.updateDivisionPhone.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    divisionPhone: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.searchCompanies.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    searchedCompanies: action.payload.data,
                    errorMessage: "",
                };
            });
        case types.saga.getCalendar.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    calendarIsLoading: false,
                    calendar: action.payload,
                    getCalendar_loading: false,
                    errorMessage: "",
                };
            });
        case types.saga.updateContact.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedContact: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.updateJobPhone.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    updatedJobPhone: action.payload,
                    updateJobLoader: false,
                    errorMessage: ''
                };
            });
        case types.saga.opportunityActiveCallAction.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    opportunityActiveCallActionResponse: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.postOpportunityAction.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    postOpportunityActionRes: action.payload,
                    plannedActionResponse: action.payload.data.main === "success" ? null : state.opportunity.data.plannedActionResponse,
                    permissionModal: true,
                    errorMessage: "",
                    latestPlannedActionResponse: action.payload.data.main === "success" ? null : state.opportunity.data.latestPlannedAction,
                };
            });
        case types.saga.getPersons.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    persons: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.getUserPipelineContent.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    userPipelineContent: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.getHistoryNoteTypes.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    historyNoteTypes: action.payload.data.main,
                    errorMessage: "",
                };
            });
        case types.saga.postPermissions.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    permissions: action.payload.success,
                    errorMessage: "",
                };
            });
        case types.saga.sendTestEmail.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    sentTestEmail: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.togglePin.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    togglePin: action.payload,
                    errorMessage: "",
                };
            });
        case types.saga.latestPlannedAction.success:
            return asyncOnSuccess(state, action, (data, action) => {
                return {
                    ...data,
                    latestPlannedActionResponse: action.payload,
                    errorMessage: "",
                };
            });
        case types.closePermissionModal:
            return {...asyncOnRequest(state, action), data: {...state.data, permissionModal: false}};
        case types.saga.getOpportunityDetails.failure:
            return {...asyncOnError(state, action), data: {...state.data, opportunity_loading: false}};
        case types.saga.getEmployees.failure:
        case types.saga.getStages.failure:
        case types.saga.getUserPipelineStages.failure:
        case types.saga.getHistory.failure:
        case types.saga.getJobs.failure:
        case types.saga.getProducts.failure:
        case types.saga.getFilteredProducts.failure:
        case types.saga.getProductOverview.failure:
        case types.saga.getSmsTemplates.failure:
        case types.saga.getMails.failure:
        case types.saga.getLostReasons.failure:
        case types.saga.getSalesReps.failure:
            return {...asyncOnError(state, action), data: {...state.data, salesReps_loading: false}};
        case types.saga.getPipelineId.failure:
        case types.saga.patchOpportunityData.failure:
        case types.saga.searchCvr.failure:
        case types.saga.getOpportunities.failure:
        case types.saga.postOpportunityData.failure:
            return {...asyncOnError(state, action), data: {...state.data, opportunity_loading: false, oppInfoSaveLoader: false}};
        case types.saga.postClosedLostReasons.failure:
        case types.saga.postUnsuccessfulCampaign.failure:
        case types.saga.postClosedWon.failure:
            return {...asyncOnError(state, action), data: {...state.data, postClosedWon_loading: false}};
        case types.saga.postNewJob.failure:
        case types.saga.switchProduct.failure:
        case types.saga.applyEmailTemplate.failure:
        case types.saga.applySmsTemplate.failure:
        case types.saga.sendEmail.failure:
        case types.saga.sendActionEmail.failure:
        case types.saga.sendSms.failure:
        case types.saga.addEventLog.failure:
        case types.saga.addCalendarEvent.failure:
        case types.saga.editCalendarEvent.failure:
        case types.saga.updateExistingJob.failure:
            Swal.fire({
                position: "center",
                icon: "error",
                title: action.error.message,
            })
            return {...asyncOnError(state, action), data: {...state.data, updateJobLoader: false}}
        case types.saga.updateStage.failure:
        case types.saga.updateDivisionPhone.failure:
        case types.saga.searchCompanies.failure:
        case types.saga.getCalendar.failure:
        case types.saga.updateContact.failure:
        case types.saga.updateJobPhone.failure:
        case types.saga.opportunityActiveCallAction.failure:
        case types.saga.getPersons.failure:
        case types.saga.getUserPipelineContent.failure:
        case types.saga.getHistoryNoteTypes.failure:
        case types.saga.postPermissions.failure:
        case types.saga.sendTestEmail.failure:
        case types.saga.togglePin.failure:
        case types.saga.latestPlannedAction.failure:
            return asyncOnError(state, action);
        default:
            return state;
    }
};

const asyncSelector = asyncSelectors((state) => state.opportunity, {
    getOpportunityDetails_loading: (data) => data.getOpportunityDetails_loading,
    salesReps_loading: (data) => data.salesReps_loading,
    getStages_loading: (data) => data.getStages_loading,
    getUserPipelineStages_loading: (data) => data.getUserPipelineStages_loading,
    getHistory_loading: (data) => data.getHistory_loading,
    checkEvent_loading: (data) => data.checkEvent_loading || data.unCheckEvent_loading,
    switchProduct_loading: (data) => data.switchProduct_loading,
    applyEmailTemplate_loading: (data) => data.applyEmailTemplate_loading || data.getMails_loading,
    applySmsTemplate_loading: (data) => data.applySmsTemplate_loading || data.getSmsTemplates_loading,
    sendEmail_loading: (data) => data.sendEmail_loading,
    sendActionEmail: (data) => data.sendActionEmail_loading,
    getMails_loading: (data) => data.getMails_loading,
    sendSms_loading: (data) => data.sendSms_loading,
    getSmsTemplates_loading: (data) => data.getSmsTemplates_loading,
    getJobs_loading: (data) => data.getJobs_loading,
    getProducts_loading: (data) => data.getProducts_loading,
    getFilteredProducts_loading: (data) => data.getFilteredProducts_loading,
    postNewJob_loading: (data) => data.postNewJob_loading,
    updateStage_loading: (data) => data.updateStage_loading,
    getCalendar_loading: (data) => data.getCalendar_loading,
    searchCompanies_loading: (data) => data.searchCompanies_loading,
    updateContact_loading: (data) => data.updateContact_loading,
    updateJobPhone_loading: (data) => data.updateJobPhone_loading,
    calendar_loading: (data) => data.calendar_loading,
    divisionPhone_loading: (data) => data.divisionPhone_loading,
    getLostReasons_loading: (data) => data.getLostReasons_loading,
    opportunityActiveCallAction_loading: (data) => data.opportunityActiveCallAction_loading,
    postOpportunityAction_loading: (data) => data.postOpportunityAction_loading,
    getPersons_loading: (data) => data.getPersons_loading,
    getUserPipelineContent_loading: (data) => data.getUserPipelineContent_loading,
    getHistoryNoteTypes_loading: (data) => data.getHistoryNoteTypes_loading,
    postUnsuccessfulCampaign_loading: (data) => data.postUnsuccessfulCampaign_loading,
});

const syncSelector = {
    isLoading: (state) => state.opportunity.loading,
    opportunity: (state) => state.opportunity.data.opportunity,
    opportunities_cache: (state) => state.opportunity.data.opportunities_cache,
    opportunity_loading: (state) => state.opportunity.data.opportunity_loading,
    getAllEvents_loading: (state) => state.opportunity.data.getAllEvents_loading,
    calendarIsLoading: (state) => state.opportunity.data.calendarIsLoading,
    getAllEvenets_inProgress: (state) => state.opportunity.data.getAllEvenets_inProgress,
    employees: (state) => state.opportunity.data.employees,
    stages: (state) => state.opportunity.data.stages,
    userPipelineStages: (state) => state.opportunity.data.userPipelineStages,
    history: (state) => state.opportunity.data.history,
    jobs: (state) => state.opportunity.data.jobs,
    opportunities: (state) => state.opportunity.data.opportunities,
    products: (state) => state.opportunity.data.products,
    filteredProducts: (state) => state.opportunity.data.filteredProducts,
    productOverview: (state) => state.opportunity.data.productOverview,
    mails: (state) => state.opportunity.data.mails,
    lostReasons: (state) => state.opportunity.data.lostReasons,
    salesReps: (state) => state.opportunity.data.salesReps,
    pipelineId: (state) => state.opportunity.data.pipelineId,
    partialOpportunityData: (state) => state.opportunity.data.partialOpportunityData,
    opportunityData: (state) => state.opportunity.data.opportunityData,
    closedLostReasons: (state) => state.opportunity.data.closedLostReasons,
    closedWon: (state) => state.opportunity.data.closedWon,
    cvr: (state) => state.opportunity.data.cvr,
    emailTemplate: (state) => state.opportunity.data.emailTemplate,
    smsTemplates: (state) => state.opportunity.data.smsTemplates,
    smsTemplate: (state) => state.opportunity.data.smsTemplate,
    searchedCompanies: (state) => state.opportunity.data.searchedCompanies,
    calendar: (state) => state.opportunity.data.calendar,
    events: (state) => state.opportunity.data.events,
    divisionPhone: (state) => state.opportunity.data.divisionPhone,
    sentSms: (state) => state.opportunity.data.sentSms,
    sentEmail: (state) => state.opportunity.data.sentEmail,
    postOpportunityActionRes: (state) => state.opportunity.data.postOpportunityActionRes,
    opportunityActiveCallActionResponse: (state) => state.opportunity.data.opportunityActiveCallActionResponse,
    persons: (state) => state.opportunity.data.persons,
    userPipelineContent: (state) => state.opportunity.data.userPipelineContent,
    historyNoteTypes: (state) => state.opportunity.data.historyNoteTypes,
    permissionModalStatus: (state) => state.opportunity.data.permissionModal,
    sendTestEmail: (state) => state.opportunity.data.sentTestEmail,
    togglePinLoading: (state) => state.opportunity.togglePin_loading,
    plannedActionResponse: (state) => state.opportunity.data.plannedActionResponse,
    latestPlannedActionResponse: (state) => state.opportunity.data.latestPlannedActionResponse,
    postClosedWonLoading: (state) => state.opportunity.data.postClosedWon_loading,
    errorMessage: (state) => state.opportunity.error,
    updateExistingJobLoading: (state) => state.opportunity.data.updateJobLoader,
    oppSaveInfoLoader: (state) => state.opportunity.data.oppInfoSaveLoader,

};

export const selectors = Object.assign({}, asyncSelector, syncSelector);
