import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {dateToString} from "../../Utils/dateUtils";
import Chip from "@mui/material/Chip";
import {useEffect, useState} from "react";
import Checkbox from "@mui/material/Checkbox";

const EventGrid = (props) => {
    const {events, selectable, selectableEventCount} = props;
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(() => {
        if (events && selectableEventCount) {
            const _selectedEvents = events.filter((e, index) => index < selectableEventCount);

            setSelectedEvents((_selectedEvents));
        }
    }, [events, selectableEventCount]);

    const isEventSelected = (eventId) => {
        return selectedEvents.filter((e) => e.event_id === eventId).length > 0
    }

    const handleCheckboxChange = (event) => {
        setSelectedEvents((prevSelected) =>
            prevSelected.find((e) => e.event_id === event.event_id)
                ? prevSelected.filter((e) => e.event_id !== event.event_id)
                : [...prevSelected, event]
        );
    };

    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            selectable && <TableCell></TableCell>
                        }
                        <TableCell>Event name</TableCell>
                        <TableCell>Event start</TableCell>
                        <TableCell>Event end</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events?.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            {

                                selectable &&
                                <TableCell><Checkbox
                                    checked={isEventSelected(row.event_id)}
                                    onChange={() => handleCheckboxChange(row)}/></TableCell>
                            }
                            <TableCell component="th" scope="row">
                                {row.event_name} {row.event_has_special_case === 1 &&
                                <Chip label="Paused" size="small"
                                      style={{height: "20px", padding: "8px"}}/>}
                            </TableCell>
                            <TableCell>{dateToString(new Date(row.event_start), 'dd/MM/yyyy H:mm')}</TableCell>
                            <TableCell>{dateToString(new Date(row.event_stop), 'dd/MM/yyyy H:mm')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default EventGrid;