import {fetchDelete, fetchGet, fetchPost} from './util';

export const getPersonInfo = (personId) => {
    return fetchGet(`/persons/group-changes/${personId}/info`).then(res => res)
}

export const getChangePersonGroup = (id) => {
    return fetchGet(`/persons/group-changes/${id}`).then(res => res)
}

export const getChangePersonGroups = (results, columns, pageIndex, query, sort) => {
    return fetchGet(`/persons/group-changes?results=${results}&columns=${columns}&offset=${pageIndex + 1}${query}${sort}`).then(res => res)
}

export const postChangePersonGroup = (personId, data) => {
    return fetchPost(`/persons/group-changes/${personId}`, data).then(res => res)
}
export const getPendingPersonGroupChange = (personId) => {
    return fetchGet(`/persons/group-changes/${personId}/pending`).then(res => res)
}

export const processChangePersonGroup = (changePersonGroupId, data) => {
    return fetchPost(`/persons/group-changes/${changePersonGroupId}/process`, data).then(res => res)
}

export const deleteChangePersonGroup = (changePersonGroupId) => {
    return fetchDelete(`/persons/group-changes/${changePersonGroupId}`).then(res => res)
}