import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { bindActionCreators } from 'redux';
import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";
import * as Survey from "survey-core";
import * as SurveyReact from "survey-react-ui";
import "survey-core/modern.css";

Survey.StylesManager.applyTheme("modern");
let neverContact = null;
let selectedBILostReasons = {};

const useStyles = _theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const LostModalV2 = (props) => {
    const { onClose, selectedValue, open, opportunity, classes, lostReasons, formDataValues } = props;
    const [jsonObj, setJsonObj] = React.useState({})
    const [opportunityProductAppType, setOpportunityProductAppType] = React.useState('F5');
    const [bIClosedLostOptions, setBIClosedLostOptions] = React.useState([]);
    const survey = new Survey.Model(jsonObj);

    useEffect(() => {
        props.getLostReasons({opportunity: opportunity?.main});
    }, [opportunity]);

    useEffect(() => {
        if (lostReasons !== null) {
            const obj = {
                showQuestionNumbers: "off",
                showCompletedPage: false,
                elements: lostReasons.main
            }
            setJsonObj(obj);
            setOpportunityProductAppType(lostReasons?.meta?.opportunity_product_app_type);

            if(lostReasons?.meta?.opportunity_product_app_type === 'BI') {
                setBIClosedLostOptions(lostReasons?.main?.map(item => item?.choices[0]));
            }
        }
    }, [lostReasons]);

    const handleClose = () => {
        if("radio_1" in selectedBILostReasons) {
            delete selectedBILostReasons['radio_1'];
        }

        onClose(selectedValue);
    };

    const completeSurvey = (event, never_contact = null) => {
        // Check the validations before completing
        neverContact = never_contact;

        if("radio_1" in selectedBILostReasons) {
            onComplete({data: selectedBILostReasons});
        } else {
            survey.completeLastPage();
        }
    };

    const onComplete = (formData, _options) => {
        let closed_lost_reasons_ids = formData.data;
        let closedLostReasonsData = {
            closed_lost_reasons_ids,
            id: props.params.id,
            userStageId: opportunity.main.pipeline_stage_id,
            person_food_considerations_1: formDataValues?.person_food_considerations_1,
            person_food_considerations_2: formDataValues?.person_food_considerations_2
        };

        props.updateOpportunityStatus({
            opportunity_status: 'closed:lost',
            person_food_considerations_1: formDataValues?.person_food_considerations_1,
            person_food_considerations_2: formDataValues?.person_food_considerations_2
        });

        if (neverContact !== null) {
            closedLostReasonsData['never_contact'] = true;
        }

        props.postClosedLostReasons(closedLostReasonsData);

        handleClose();
    }

    const handleRadioBoxItemClick = (event) => {
        selectedBILostReasons['radio_1'] = event.target.value;
    };

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BootstrapDialogTitle>In order to close:lost this opportunity, you have to pick one or more reasons from below</BootstrapDialogTitle>
            <DialogContent>
                {opportunityProductAppType === 'F5' && <SurveyReact.Survey
                    model={survey}
                    onComplete={onComplete}
                />}

                {opportunityProductAppType === 'BI' &&
                    <FormControl component="fieldset">
                        <RadioGroup name="radio-buttons-group" onChange={handleRadioBoxItemClick}>
                            {bIClosedLostOptions?.map((item, index) => (
                                <FormControlLabel key={item?.value} value={item?.value} control={<Radio />} label={item?.text} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                }
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='warning' onClick={completeSurvey}>Closed:lost</Button>
                <Button className={classes.button} variant="contained" color="error" onClick={(e) => completeSurvey(e, true)}>Closed:lost never contact</Button>
                <Button className={classes.button} variant="contained" color="primary" onClick={handleClose}>Cancel closed:lost action</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    isLoading: opportunitySelectors.getLostReasons_loading(state),
    errorMessage: opportunitySelectors.errorMessage(state),
    lostReasons: opportunitySelectors.lostReasons(state),
    opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getLostReasons: opportunityActions.getLostReasons,
        postClosedLostReasons: opportunityActions.postClosedLostReasons,
        updateOpportunityStatus: opportunityActions.updateOpportunityStatus
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <LostModalV2 {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));