import * as React from 'react';
import { withStyles } from '@mui/styles';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../Ducks/opportunity';
import { selectors as eventsOverviewSelectors, actions as eventsOverviewActions } from '../../../Ducks/eventsOverview';
import { selectors as productsSelectors, actions as productsActions } from '../../../Ducks/products';
import { selectors as accountSelectors } from '../../../Ducks/account';
import { selectors as companiesSelectors, actions as companiesActions } from '../../../Ducks/companies';
import { selectors as ContactsSelectors, actions as contactsActions } from "../../../Ducks/contacts";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tabs, Tab, Typography, Box, Grid, Autocomplete, TextField as MaterialTextField, Tooltip } from '@mui/material';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PersonOverviewTab from './Tabs/PersonOverviewTab';
import { Close, FastForward, FiberNew, Pause, Star } from '@mui/icons-material';
import EventInfoTab from './Tabs/EventInfoTab';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import addWeeks from 'date-fns/addWeeks';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cloneDeep } from 'lodash';
import SpecialCaseTab from './Tabs/SpecialCaseTab';
import ProgressBar from './Widgets/ProgressBar';

toast.configure();

const useStyles = _theme => ({
    container: {
        textAlign: 'left',
    },
    tabs: {
        marginTop: '0'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    headline: {
        margin: '0 0 10px',
        minWidth: '300px',
        fontWeight: '400',
        fontSize: '20px',
        position: 'relative',
        flex: 1
    },
    stepper: {
        flex: 2
    },
    status: {
        color: '#fff',
        fontSize: '12px',
        textTransform: 'uppercase',
        fontWeight: '400',
        padding: '3px 10px',
        borderRadius: '4px',
        marginBottom: '4px',
        position: 'absolute',
        top: '11px'
    },
    opportunityID: {
        margin: '0 5px'
    },
    tabsStyling: {
        border: '1px solid #dcdbdb !important',
        opacity: '1 !important',
        fontWeight: '600 !important',
        textTransform: 'capitalize'
    },
    selectBox: {
        marginLeft: '10px',
        padding: '10px'
    },
    attending: {
        background: '#68a54d',
        color: '#fff'
    },
    not_attending: {
        background: '#db6669',
        color: '#fff'
    },
    unknown: {
        background: '',
        color: '#000'
    },
    tentative: {
        background: '#f9fd01',
        color: '#000'
    },
    resale_attending: {
        background: '#5ecd5e',
        color: '#fff'
    },
    resale_not_attending: {
        background: '#e44848',
        color: '#fff'
    },
    resale_unknown: {
        background: '#e2e23b',
        color: '#000'
    },
    resale_tentative: {
        background: '#f1b851',
        color: '#000'
    },
    hide: {
        display: 'none'
    },
    form: {
        display: 'flex'
    },
    member: {
        position: 'relative'
    },
    indicator: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '15px',
        height: '15px',
        background: 'blue'
    }
});

const tabListData = [
    {
        name: 'person',
        disabled: false,
        hidden: false,
        backgroundColor: "#f5f5f5",
        color: "#3a3641"
    },
    {
        name: 'event',
        disabled: false,
        hidden: false,
        backgroundColor: "#f5f5f5",
        color: "#3a3641"
    },
    {
        name: 'special case',
        disabled: false,
        hidden: true,
        backgroundColor: 'purple',
        color: '#fff'
    }
];

const months = [
    {
        name: 'January',
        value: 1
    },
    {
        name: 'February',
        value: 2
    },
    {
        name: 'March',
        value: 3
    },
    {
        name: 'April',
        value: 4
    },
    {
        name: 'May',
        value: 5
    },
    {
        name: 'June',
        value: 6
    },
    {
        name: 'July',
        value: 7
    },
    {
        name: 'August',
        value: 8
    },
    {
        name: 'September',
        value: 9
    },
    {
        name: 'October',
        value: 10
    },
    {
        name: 'November',
        value: 11
    },
    {
        name: 'December',
        value: 12
    },
]

class EventsOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            range: [null, null],
            selectedTab: 'person',
            selectedOpportunity: '32',
            product: "",
            productType: "",
            user: "",
            country: "",
            productList: [],
            productTypesList: [],
            countryList: [
                {
                    country_aliases: "Danmark,Denemarken",
                    country_code: "DK",
                    country_id: 1,
                    country_name: "Denmark",
                    label: "Denmark",
                    key: 1
                },
                {
                    country_aliases: "Sverige",
                    country_code: "SE",
                    country_id: 8,
                    country_name: "Sweden",
                    label: "Sweden",
                    key: 1
                }
            ],
            users: [],
            networkDirectors: [],
            networkDirector: "",
            selected: null,
            pipelineStageId: null,
            assignedOpportunity: false,
            tabList: tabListData,
            split: false,
            overview: [],
            person: {},
            eventInfo: {},
            currentMonth: "",
            currentYear: moment().year(),
            loading: false,
            scrollPercentage: 0,
            sortOrder: "",
            sortOrderOptions: [
                {
                    label: 'By Order',
                    key: 1,
                    value: 1
                },
                {
                    label: 'By Name',
                    key: 2,
                    value: 2
                }
            ],
        }
        this.scrollingDiv = React.createRef();
    }

    componentDidMount() {
        this.initialize();
        this.fetchProductTypes();
        this.fetchUsers();
        this.fetchCountries();
        this.fetchNetworkDirectors();
        this.getEventsOverviewSheet();
    }

    componentDidUpdate(prevProps, _prevState) {
        const { getFilteredProducts, products, productTypes, eventsOverviewUsers, filteredProducts, eventsOverview, user, countries, networkDirectors, isNetworkDirector } = this.props;

        if (prevProps.products !== products) {
            let sorted = products.sort(function (a, b) {
                if (a.product_name < b.product_name) { return -1; }
                if (a.product_name > b.product_name) { return 1; }
                return 0;
            });
            let productSortedList = sorted.map((item, _index) => {
                item.label = item.product_name;
                item.key = item.product_id;
                return item;
            });

            this.setState({ productList: productSortedList })
        }
        if (prevProps.productTypes !== productTypes) {
            let productTypesList = productTypes.main.map((item, _index) => {
                item.label = item.product_type_name;
                item.key = item.product_type_id
                return item;
            });
            const productType = productTypesList.filter((item) => item.product_type_id === 1)[0];
            const queryString = `results=500&equals[product_show_in_sales]=1&equals[product_type_id]=${productType.product_type_id}&equals[country_id]=${user.user_details.country_id}`
            getFilteredProducts(queryString);
            this.setState({ productTypesList, productType });
        }
        if (prevProps.eventsOverviewUsers !== eventsOverviewUsers) {
            let _salesRep = eventsOverviewUsers.salesrep.map(v => ({ ...v, type: "Resale Sales Rep", user_type: 1 }));
            let _member_service_agents = eventsOverviewUsers.member_service_agents.map(v => ({ ...v, type: "Member Service", user_type: 2 }));
            const _merged_users = _salesRep.concat(_member_service_agents);

            let _users = _merged_users.filter(item => item.user_deactivated === null && !item.user_lastname.includes("WRONG")).map((item, index) => {
                item.label = item.user_firstname + " " + item.user_lastname;
                item.key = index
                return item;
            });
            const _user = _users.filter((item) => item.user_id === user.user_details.user_id)[0];
            this.setState({ users: _users, user: isNetworkDirector ? "" : _user });
        }
        if (prevProps.filteredProducts !== filteredProducts) {
            let sorted = filteredProducts.sort(function (a, b) {
                if (a.product_name < b.product_name) { return -1; }
                if (a.product_name > b.product_name) { return 1; }
                return 0;
            });
            let productSortedList = sorted.map((item, _index) => {
                item.label = item.product_name;
                item.key = item.product_id;
                return item;
            });

            this.setState({ productList: productSortedList })
        }
        if (prevProps.countries !== countries) {
            let _countries = countries.main.map((item, _index) => {
                item.label = item.country_name;
                item.key = item.country_id
                return item;
            });
            const _country = _countries.filter((item) => item.country_id === user.user_details.country_id)[0];
            this.setState({ countryList: _countries, country: _country });
        }
        if (prevProps.networkDirectors !== networkDirectors) {
            let _networkDirectors = networkDirectors.map((item, _index) => {
                item.label = item.person_name;
                item.key = Math.random()
                return item;
            });
            const _networkDirector = _networkDirectors.filter((item) => item.id === user.user_details.job_id)[0];
            this.setState({ networkDirectors: _networkDirectors, networkDirector: isNetworkDirector ? _networkDirector : "" });
        }
        if (prevProps.eventsOverview !== eventsOverview) {
            this.setState({ loading: false });

            const _events = cloneDeep(eventsOverview);

            _events.sort(function (a, b) {
                return new Date(a.event_start) - new Date(b.event_start);
            });

            // sort participants
            if (this.state.sortOrder.value === 2) {
                _events.forEach((item) => {
                    const _participants_details = item.participants_details
                    _participants_details.sort(function (a, b) {
                        if (a.person_firstname < b.person_firstname) { return -1; }
                        if (a.person_firstname > b.person_firstname) { return 1; }
                        return 0;
                    })
                })
            }

            this.setState({ overview: _events });
        }
    }

    initialize = () => {
        const { isNetworkDirector } = this.props;
        const start = new Date(new Date().setHours(0, 0, 0, 0));
        const end = isNetworkDirector ? new Date(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0)) : new Date(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)).setHours(0, 0, 0, 0));
        const range = [start, end]
        const m = moment(start).format("MM");
        const current = months.filter((item) => item.value === parseInt(m));
        this.setState({ currentMonth: current[0], range });
    }

    fetchProducts = () => {
        const { getProducts, user } = this.props;
        let countryID = user.user_details.country_id;
        let productShowInSales = 0;
        let results = 100;
        getProducts({ countryID, productShowInSales, results });
    }

    fetchProductTypes = () => {
        const { getProductTypes } = this.props;
        getProductTypes();
    }

    fetchUsers = () => {
        const { getEventsOverviewUsers } = this.props;
        getEventsOverviewUsers();
    }

    fetchCountries = () => {
        const { user } = this.props;
        const { countryList } = this.state;
        // This is only until an API is called
        const _country = countryList.filter((item) => item.country_id === user.user_details.country_id)[0];
        this.setState({ country: _country });
    }

    fetchNetworkDirectors = () => {
        const { getNetworkDirectors } = this.props;
        getNetworkDirectors();
    }

    getEventsOverviewSheet = () => {
        const { getEventsOverview, user, isNetworkDirector } = this.props;
        const capabilities = user.user_details.capabilities;
        const isSalesRep = capabilities.filter(item => item.capability_title === "salesrep").length
        let query;

        const d = new Date();
        const start = moment(d).format('YYYY-MM-DD');
        const end = isNetworkDirector ? moment(new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000))).format('YYYY-MM-DD') : moment(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000))).format('YYYY-MM-DD');
        const query1 = `equals[event_start_date]=${start}&equals[event_end_date]=${end}`;
        const query2 = `equals[product_type_id]=1`;
        const query3 = `equals[user_id]=${user.user_details.user_id}&equals[user_type]=${isSalesRep ? 1 : 2}`;
        const query4 = `equals[country_id]=${user.user_details.country_id}`;
        const query5 = `equals[network_directors]=${user.user_details.job_id}`;
        if (capabilities.filter(item => item.capability_title === "event_overview").length) {
            query = `${query1}&${query2}&${query3}&${query4}`;
        } else {
            query = `${query1}&${query2}&${query4}`;
        }
        if (isNetworkDirector) {
            query = `${query}&${query5}`;
        }
        this.setState({ loading: true });
        getEventsOverview(query);
    }

    handleChange = (_e, newValue) => {
        this.setState({ selectedTab: newValue });
    }

    renderSelectedComponent = (selectedTab) => {
        const { person, eventInfo, currentMonth, currentYear, range, product, productType, user, users, country, networkDirector } = this.state;
        const query = {
            range,
            product,
            productType,
            user,
            country,
            networkDirector
        }
        switch (selectedTab) {
            case 'person': return <PersonOverviewTab person={person} eventInfo={eventInfo} selectedMonth={currentMonth} selectedYear={currentYear} query={query} isNetworkDirector={this.props.isNetworkDirector} users={users} handleTabChange={this.handleChange} toggleSpecialCaseTab={this.toggleSpecialCaseTab} />;
            case 'event': return <EventInfoTab eventInfo={eventInfo} isNetworkDirector={this.props.isNetworkDirector} />;
            case 'special case': return <SpecialCaseTab person={person} eventInfo={eventInfo} query={query} isNetworkDirector={this.props.isNetworkDirector} />;
            default: return <PersonOverviewTab person={person} eventInfo={eventInfo} selectedMonth={currentMonth} selectedYear={currentYear} query={query} isNetworkDirector={this.props.isNetworkDirector} />;
        }
    }

    checkOpportunityAssignment = (opportunity, pipelines) => {
        if (pipelines !== null) {
            const pipeline_stage_id = opportunity.main.pipeline_stage_id;
            const pipelines_arr = pipelines.main.pipeline_stages;
            if (pipelines_arr.includes(pipeline_stage_id)) {
                this.setState({ assignedOpportunity: true });
            }
            else {
                this.setState({ assignedOpportunity: false });
            }
        }
    }

    toggleSpecialCaseTab = (visibility) => {
        const { tabList } = this.state;
        this.setState({
            tabList: tabList.map(x => {
                if (x.name === 'special case') {
                    x.hidden = visibility;
                }
                return x;
            })
        });
    }

    filterTabList = (tabList) => {
        return tabList.filter(item => {
            // Enable this if need to hide the tab initially
            if (item.hidden) {
                return false
            }

            return true;
        })
    }

    columnVisibility = (index) => {
        const { selected, split } = this.state;
        if (split && selected !== null) {
            if (selected <= index && selected - index > -4) {
                return 'show';
            }
            else {
                return 'hide';
            }
        }
        else {
            return 'show';
        }
    }

    filter = (name, _event, value) => {
        const { getFilteredProducts } = this.props;
        const { country, productType } = this.state;
        let queryString = '';

        if (value !== null) {
            if (name === 'productType') {
                queryString = country ? `results=100&equals[product_show_in_sales]=0&equals[product_type_id]=${value.product_type_id}&equals[country_id]=${country.country_id}` : `results=100&equals[product_show_in_sales]=0&equals[product_type_id]=${value.product_type_id}`
                this.setState({ product: "" });
                getFilteredProducts(queryString);
            }
            if (name === 'country') {
                queryString = productType ? `results=100&equals[product_show_in_sales]=0&equals[product_type_id]=${productType.product_type_id}&equals[country_id]=${value.country_id}` : `results=100&equals[product_show_in_sales]=0&equals[country_id]=${value.country_id}`
                this.setState({ product: "" });
                getFilteredProducts(queryString);
            }
            this.setState({ [name]: value, split: false, selected: null });
        }
        else {
            if (name === 'productType') {
                if (country) {
                    queryString = `results=100&equals[product_show_in_sales]=0&equals[country_id]=${country.country_id}`
                } else {
                    queryString = `results=100&equals[product_show_in_sales]=0`
                }
                this.setState({ product: "" });
                getFilteredProducts(queryString);
            }
            if (name === 'country') {
                if (productType) {
                    queryString = `results=100&equals[product_show_in_sales]=0&equals[product_type_id]=${productType.product_type_id}`
                } else {
                    queryString = `results=100&equals[product_show_in_sales]=0`
                }
                this.setState({ product: "" });
                getFilteredProducts(queryString);
            }
            this.setState({ [name]: "", split: false, selected: null });
        }
    }

    renderParticipants = (eventInfo, participants, _idx) => {
        const { classes } = this.props;
        const { selected } = this.state;
        return participants.map((person, _index) => {
            return (
                <li key={Math.random()} onClick={(_event) => {
                    this.setState({ split: true, selected: person.potential_participant_id, person: person, eventInfo: eventInfo }, () => {
                        if (person.special_case_type) {
                            this.toggleSpecialCaseTab(false);
                        } else {
                            this.toggleSpecialCaseTab(true);
                        }
                        document.getElementsByClassName('scrolling_box')[0].scrollTo({
                            left: document.getElementsByClassName('selected_person')[0].offsetLeft - document.getElementsByClassName('fixed_bar')[0].clientWidth - 24,
                            top: document.getElementsByClassName('scrolling_box')[0].scrollTop,
                            behavior: 'smooth'
                        })
                    })
                }} className={`${classes[this.getStatus(person.member_info, person.answer.value)]} ${selected === person.potential_participant_id ? 'selected_person' : ''} ${this.getStatus(person.member_info, person.answer.value)} ${person.special_case_type ? 'special_case' : ''} ${person.event_special_case ? 'event_paused' : ''}`} style={{ outline: "1px solid #000", cursor: "pointer" }}>{this.getParticipant(person.member_info, person.answer.value, person)}</li>
            )
        })
    }

    getStatus = (_member_info, answer) => {
        return answer
    }

    getParticipant = (member_info, _answer, person) => {
        if (member_info === "Resale") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="Resale">
                            <React.Fragment>
                                <Star sx={{ fontSize: '20px', color: "#fbd400" }} />
                                {
                                    person.special_case_type === "pause" ? <Pause sx={{ color: "purple" }} style={{fontSize:'25px'}}/> : person.special_case_type === "ongoing" ? <FastForward sx={{ color: "purple" }} style={{fontSize:'25px'}}/> : <></>
                                }
                            </React.Fragment>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else if (member_info === "New") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="New">
                            <React.Fragment>
                                <FiberNew sx={{ fontSize: '20px', color: "#000" }} />
                                {
                                    person.special_case_type === "pause" ? <Pause sx={{ color: "purple" }} style={{fontSize:'25px'}}/> : person.special_case_type === "ongoing" ? <FastForward sx={{ color: "purple" }} style={{fontSize:'25px'}}/> : <></>
                                }
                            </React.Fragment>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else if (member_info === "New | Resale") {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Tooltip title="New Resale">
                            <React.Fragment>
                                <FiberNew sx={{ fontSize: '20px', color: "#000" }} />
                                <Star sx={{ fontSize: '20px', color: "#fbd400" }} />
                                {
                                    person.special_case_type === "pause" ? <Pause sx={{ color: "purple" }} style={{fontSize:'25px'}}/> : person.special_case_type === "ongoing" ? <FastForward sx={{ color: "purple" }} style={{fontSize:'25px'}}/> : <></>
                                }
                            </React.Fragment>
                        </Tooltip>
                    </span>
                    <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
                </div>
            )
        } else {
            return (
                <span className="participant">{person.person_firstname}{" "}{person.person_lastname}</span>
            )
        }
    }

    getWeeksAfter = (date, amount) => {
        return date ? addWeeks(date, amount) : undefined;
    }

    getMonthData = navigation => {
        this.setState({ loading: true });
        const { getEventsOverview } = this.props;
        const { currentMonth, currentYear } = this.state;
        if (navigation === 'previous') {
            if (currentMonth.value === 1) {
                this.setState({
                    currentYear: currentYear - 1,
                    currentMonth: months.filter((item) => item.value === 12)[0]
                });
                const date = currentYear - 1 + "-" + this.zeroPad(months.filter((item) => item.value === 12)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getEventsOverview(query);
            } else {
                this.setState({
                    currentMonth: months.filter((item) => item.value === currentMonth.value - 1)[0]
                })
                const date = currentYear + "-" + this.zeroPad(months.filter((item) => item.value === currentMonth.value - 1)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getEventsOverview(query);
            }
        }

        if (navigation === 'next') {
            if (currentMonth.value === 12) {
                this.setState({
                    currentYear: currentYear + 1,
                    currentMonth: months.filter((item) => item.value === 1)[0]
                })
                const date = currentYear + 1 + "-" + this.zeroPad(months.filter((item) => item.value === 1)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getEventsOverview(query);
            } else {
                this.setState({
                    currentMonth: months.filter((item) => item.value === currentMonth.value + 1)[0]
                })
                const date = currentYear + "-" + this.zeroPad(months.filter((item) => item.value === currentMonth.value + 1)[0].value, 2)
                const query = `contains[event_start]=${date}`
                getEventsOverview(query);
            }
        }
    }

    // helper
    zeroPad = (num, places) => String(num).padStart(places, '0');

    resetMonthFilter = () => {
        this.setState({ overview: [] });
        const { getEventsOverview } = this.props;
        const d = new Date();
        const year = moment(d).format('YYYY');
        const month = moment(d).format('MM');
        const date = year + "-" + month;
        const query = `contains[event_start]=${date}`

        this.setState({
            currentYear: year,
            currentMonth: months.filter((item) => item.value === parseInt(month))[0]
        });

        getEventsOverview(query);
    }

    getQuery = (filter, value, start = null, end = null) => {
        const { user, country, productType, product, range, networkDirector } = this.state;
        var start_date = "", end_date = "";
        if (start === null && end === null) {
            start_date = moment(range[0]).format('YYYY-MM-DD');
            end_date = moment(range[1]).format('YYYY-MM-DD');
        } else {
            start_date = moment(start).format('YYYY-MM-DD');
            end_date = moment(end).format('YYYY-MM-DD');
        }
        this.setState({ loading: true });

        let query = `equals[event_start_date]=${start_date}&equals[event_end_date]=${end_date}`;
        if (filter === "productType" && value !== null) {
            query = `${query}&equals[product_type_id]=${value.product_type_id}`
        }
        if (filter === "product" && value !== null) {
            query = `${query}&equals[product_id]=${value.product_id}`
        }
        if (filter === "user" && value !== null) {
            query = `${query}&equals[user_id]=${value.user_id}&equals[user_type]=${value.user_type}`
        }
        if (filter === "country" && value !== null) {
            query = `${query}&equals[country_id]=${value.country_id}`
        }
        if (filter === "networkDirector" && value !== null) {
            query = `${query}&equals[network_directors]=${value.id}`
        }

        if (productType !== "" && productType !== null && productType !== undefined && filter !== "productType") {
            query = `${query}&equals[product_type_id]=${productType.product_type_id}`
        }
        if (product !== "" && product !== null && product !== undefined && (filter !== "product" && filter !== "productType")) {
            query = `${query}&equals[product_id]=${product.product_id}`
        }
        if (user !== "" && user !== null && user !== undefined && filter !== "user") {
            query = `${query}&equals[user_id]=${user.user_id}&equals[user_type]=${user.user_type}`
        }
        if (country !== "" && country !== null && country !== undefined && filter !== "country") {
            query = `${query}&equals[country_id]=${country.country_id}`
        }
        if (networkDirector !== "" && networkDirector !== null && networkDirector !== undefined && filter !== "networkDirector") {
            query = `${query}&equals[network_directors]=${networkDirector.id}`
        }
        return query;
    }
    render() {
        const { classes, eventsOverview, isNetworkDirector,contactDetails } = this.props;
        const { selectedTab, tabList, split, product, productList, productType, productTypesList, user, users, overview, country, countryList, loading, range, sortOrder, sortOrderOptions, networkDirector, networkDirectors } = this.state;
        
        return (
            <div className={classes.container}>
                <div className={classes.title}>
                    <h2 className={classes.headline}>
                        Events Overview
                    </h2>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={4}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', marginRight: '5px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateRangePicker
                                                disableOpenPicker={false}
                                                value={range}
                                                inputFormat="dd/MM/yyyy"
                                                className="date-range-picker"
                                                onChange={(newValue) => {
                                                    if (newValue[0] && newValue[1] && range[0] && range[1] && newValue[0].getTime() === range[0].getTime() && newValue[1].getTime() === range[1].getTime()) {
                                                        // Do nothing
                                                    } else if ((newValue[0] !== null && newValue[1] !== null)) {
                                                        const query = this.getQuery('picker', null, newValue[0], newValue[1]);
                                                        this.props.getEventsOverview(query);
                                                        this.setState({ range: newValue });
                                                    }

                                                }}
                                                renderInput={(startProps, endProps) => (
                                                    <React.Fragment>
                                                        <MaterialTextField {...startProps} sx={{ width: '140px' }} />
                                                        <Box sx={{ mx: 2 }}> to </Box>
                                                        <MaterialTextField disabled {...endProps} sx={{ width: '140px' }} />
                                                    </React.Fragment>
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Autocomplete
                                    disablePortal
                                    value={sortOrder}
                                    id="combo-box-demo"
                                    options={sortOrderOptions}
                                    sx={{ width: 200, marginRight: '10px' }}
                                    filterOptions={(options, _state) => options}
                                    onChange={(_event, value) => {
                                        if (value && value.value === 1) {
                                            const _events = cloneDeep(eventsOverview);
                                            _events.sort(function (a, b) {
                                                return new Date(a.event_start) - new Date(b.event_start);
                                            });
                                            this.setState({ overview: _events, sortOrder: value });

                                        } else if (value && value.value === 2) {
                                            const _events = cloneDeep(eventsOverview);
                                            _events.sort(function (a, b) {
                                                return new Date(a.event_start) - new Date(b.event_start);
                                            });

                                            // sort participants
                                            _events.forEach((item) => {
                                                const _participants_details = item.participants_details
                                                _participants_details.sort(function (a, b) {
                                                    if (a.person_firstname < b.person_firstname) { return -1; }
                                                    if (a.person_firstname > b.person_firstname) { return 1; }
                                                    return 0;
                                                })
                                            })
                                            // sort participants
                                            this.setState({ overview: _events, sortOrder: value });

                                        } else {
                                            // Do Nothing
                                            this.setState({ sortOrder: "" });
                                        }
                                    }}
                                    renderInput={(params) => <MaterialTextField {...params} label="Sort Members" />}
                                />
                                <Autocomplete
                                    disablePortal
                                    value={productType}
                                    id="combo-box-demo"
                                    options={productTypesList}
                                    sx={{ width: 200, marginRight: '10px' }}
                                    onChange={(event, value) => {
                                        this.filter('productType', event, value);
                                        const query = this.getQuery('productType', value);
                                        this.props.getEventsOverview(query);
                                    }}
                                    renderInput={(params) => <MaterialTextField {...params} label="Product Type" />}
                                />
                                <Autocomplete
                                    disablePortal
                                    value={product}
                                    id="combo-box-demo"
                                    options={productList}
                                    sx={{ width: 200, marginRight: '10px' }}
                                    onChange={(event, value) => {
                                        this.filter('product', event, value)
                                        const query = this.getQuery('product', value);
                                        this.props.getEventsOverview(query);
                                    }}
                                    renderInput={(params) => <MaterialTextField {...params} label="Product" />}
                                />
                                {
                                    !isNetworkDirector && (
                                        <Autocomplete
                                            disablePortal
                                            value={user}
                                            id="combo-box-demo"
                                            options={users}
                                            groupBy={(option) => option.type}
                                            sx={{ width: 250, marginRight: '10px' }}
                                            onChange={(event, value) => {
                                                this.filter('user', event, value);
                                                const query = this.getQuery('user', value);
                                                this.props.getEventsOverview(query);
                                            }}
                                            renderOption={(props, option, _state) => {
                                                return (
                                                    <div {...props}>
                                                        <div style={{ textAlign: 'left', padding: '0', fontSize: '13px', borderBottom: '1px solid #eaeaea' }}>
                                                            <span>{`${option.label}`}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                            renderInput={(params) => <MaterialTextField {...params} label="User" />}
                                        />
                                    )
                                }

                                <Autocomplete
                                    disablePortal
                                    value={country}
                                    id="combo-box-demo"
                                    options={countryList}
                                    sx={{ width: 200, marginRight: '10px' }}
                                    onChange={(event, value) => {
                                        this.filter('country', event, value);
                                        const query = this.getQuery('country', value);
                                        this.props.getEventsOverview(query);
                                    }}
                                    renderInput={(params) => <MaterialTextField {...params} label="Country" />}
                                />
                                {
                                    !isNetworkDirector && (
                                        <Autocomplete
                                            disablePortal
                                            value={networkDirector}
                                            id="combo-box-demo"
                                            options={networkDirectors}
                                            sx={{ width: 200 }}
                                            onChange={(event, value) => {
                                                this.filter('networkDirector', event, value);
                                                const query = this.getQuery('networkDirector', value);
                                                this.props.getEventsOverview(query);
                                            }}
                                            renderInput={(params) => <MaterialTextField {...params} label="Network Director" />}
                                        />
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={split ? 6 : 12}>
                        <Grid container spacing={2}>
                            {
                                loading ? (
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    overview.length ? (
                                        <Grid item xs={12} sx={{ minHeight: '300px' }}>
                                            <div>
                                                <div style={{ display: 'flex' }}>
                                                    <div className="fixed_bar" style={{ width: '250px', textAlign: 'center', border: '1px solid', boxShadow: '10px 4px 15px 0px rgb(0 0 0 / 24%)', position: 'relative', zIndex: 999 }}>
                                                        <ul style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%' }}>
                                                            <li style={{ borderBottom: '1px solid', background: '#9a9a9a', color: '#fff', padding: '5px' }}><b>Name</b></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Next Meeting</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Start Time</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>End Time</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Total Members</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Showed</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Meeting %</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Expected Meeting %</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Resale %</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>General Satisfaction</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Average Recommendation</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Keynote</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Network Unity</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Invitation Sendout</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px', minHeight: "60px" }}><span style={{ visibility: 'hidden' }}>Empty</span></li>
                                                            <li style={{ background: '#68a54d', color: '#fff', borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Attending</span></li>
                                                            <li style={{ background: '#db6669', color: '#fff', borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Not Attending</span></li>
                                                            <li style={{ background: '#f9fd01', color: '#000', borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Tentative</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Unknown</span></li>
                                                            <li style={{ background: '#646464', color: '#fff', borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Paused</span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>Resale <Star sx={{ fontSize: '20px', color: "#fbd400" }} /> </span></li>
                                                            <li style={{ borderBottom: '1px solid', fontSize: '14px' }}><span style={{ fontWeight: 600 }}>New Member <FiberNew sx={{ fontSize: '20px', color: "#000" }} /> </span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="scrolling_box" ref={this.scrollingDiv} style={{ display: 'flex', width: '100%', maxWidth: '100%', overflowY: 'auto', maxHeight: split ? window.innerHeight - 225 : window.innerHeight - 225 }}>
                                                        {
                                                            overview && eventsOverview && overview.map((item, idx) => {
                                                                
                                                                return (
                                                                    <div key={idx} style={{ minWidth: split ? '25%' : '12.5%', textAlign: 'center', borderTop: '1px solid' }}>
                                                                        <ul style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%' }}>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', background: '#255d77', color: '#fff', padding: '5px' }}>
                                                                                <Tooltip title={<React.Fragment><div>{item.event_name}</div><div>{item.product_name}</div></React.Fragment>}>
                                                                                    <b>{item.product_abbreviation}</b>
                                                                                </Tooltip>
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.event_start ? moment(item.event_start).format("DD-MM-YYYY") : 'N/A'}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.event_start ? moment(item.event_start).format("HH:mm") : 'N/A'}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px'}}>
                                                                                {item.event_stop ? moment(item.event_stop).format("HH:mm") : 'N/A'}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.Total}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.Showed}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.Meeting_Percentage}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.expected_meeting_percentage} 
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                <ProgressBar percentage={item.resale_percentage}/>
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.General_Satisfaction}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.Average_Recommendation}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.Keynote !== undefined ? item.participants_count.Keynote : 'N/A'}
                                                                            </li>
                                                                            <li style={{ borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}>
                                                                                {item.participants_count.Network_Unity !== undefined ? item.participants_count.Network_Unity : 'N/A'}
                                                                            </li>
                                                                            <li style={{ minHeight: '22px', borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px', background: item.invitation_send_out }}>

                                                                            </li>
                                                                            <li style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "60px", background: "#255d77", color: "#fff", borderBottom: '1px solid', borderRight: '1px solid', fontSize: '14px' }}><span>{item.username}</span></li>
                                                                        </ul>
                                                                        <ul style={{ listStyle: 'none', padding: 0, margin: 0, width: '100%' }}>
                                                                            {

                                                                                this.renderParticipants(item, item.participants_details, idx)
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography component="h1" variant="h5" sx={{ margin: '20px', textAlign: 'center' }}>
                                                No Record Found
                                            </Typography>
                                        </Grid>
                                    )
                                )
                            }

                        </Grid>
                    </Grid>
                    {
                        split ? (
                            <Grid item xs={6} sx={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', right: '8px', top: '20px', zIndex: '9999', cursor: 'pointer' }}>
                                    <Close onClick={() => {
                                        this.setState({ split: false, person: {}, eventInfo: {}, selected: null }, () => {
                                        });
                                    }} />
                                </div>
                                <Tabs
                                    className={classes.tabs}
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    style={{ backgroundColor: '#F1F1F1' }}
                                >
                                    {
                                        tabList.map((item, index) => <Tab key={index} className={classes.tabsStyling} label={item.name} value={item.name} disabled={item.disabled} style={{ backgroundColor: item.name === 'special case' && contactDetails !== null && contactDetails !== undefined && contactDetails.main[0].hasOwnProperty('special_case_status') ?  item.backgroundColor : '#f5f5f5', 
                                            color: item.name === 'special case' && contactDetails !== null && contactDetails !== undefined && contactDetails.main[0].hasOwnProperty('special_case_status') ?  item.color : '#3a3641'
                                        }} />)
                                    }
                                </Tabs>
                                <div
                                    role="tabpanel"
                                    id={`full-width-tabpanel`}
                                    aria-labelledby={`full-width-tab`}
                                    style={{ padding: '10px', background: '#efefef', overflowY: 'auto', height: window.innerHeight - 273 }}
                                >
                                    {
                                        this.renderSelectedComponent(selectedTab)
                                    }
                                </div>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }

                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    productsLoading: opportunitySelectors.getProducts_loading(state),
    eventsOverviewLoading: eventsOverviewSelectors.getEventsOverview_loading(state),
    products: opportunitySelectors.products(state),
    productTypes: productsSelectors.productTypes(state),
    filteredProducts: opportunitySelectors.filteredProducts(state),
    eventsOverview: eventsOverviewSelectors.eventsOverview(state),
    networkDirectors: eventsOverviewSelectors.networkDirectors(state),
    eventsOverviewUsers: eventsOverviewSelectors.eventsOverviewUsers(state),
    errorMessage: eventsOverviewSelectors.errorMessage(state),
    countries: companiesSelectors.countries(state),
    user: accountSelectors.user(state),
    opportunity: opportunitySelectors.opportunity(state),
    contactDetails: ContactsSelectors.contactDetails(state),
});


const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        getProducts: opportunityActions.getProducts,
        getProductTypes: productsActions.getProductTypes,
        getFilteredProducts: opportunityActions.getFilteredProducts,
        getEventsOverview: eventsOverviewActions.getEventsOverview,
        getNetworkDirectors: eventsOverviewActions.getNetworkDirectors,
        getEventsOverviewUsers: eventsOverviewActions.getEventsOverviewUsers,
        getCountries: companiesActions.getCountries,
        getOpportunityDetails: opportunityActions.getOpportunityDetails
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <EventsOverview {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
