import * as React from 'react';
import {
    Paper,
    FormControlLabel,
    Checkbox,
    Button,
    TextField,
    List,
    ListItem,
    Box,
    ListItemText,
    Container,
    Typography,
    Autocomplete,
    Chip
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import opportunity, {selectors as opportunitySelectors, actions as opportunityActions} from '../../../../../Ducks/opportunity';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {selectors as accountSelectors} from '../../../../../Ducks/account';
import {useNavigate, useParams} from 'react-router-dom';
import {EditingState, IntegratedEditing, ViewState,} from '@devexpress/dx-react-scheduler';
import {CircularProgress} from "@mui/material";
import {
    Scheduler,
    DayView,
    WeekView,
    Appointments,
    Toolbar,
    DateNavigator,
    TodayButton,
    MonthView,
    Resources,
    AppointmentForm,
    AppointmentTooltip,
    ConfirmationDialog
} from '@devexpress/dx-react-scheduler-material-ui';
import AddEventModal from './AddEventModal';
import SendEmailModal from './SendEmailModal';
import moment from 'moment';
import {styled} from '@mui/material/styles';

const ShowProgress = () => (
    <div style={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress/>
    </div>
)

const PREFIX = 'Demo';

const classes = {
    dayScaleCell: `${PREFIX}-dayScaleCell`,
};

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)({
    [`&.${classes.dayScaleCell}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

const formatDayScaleDate = (date, options) => {
    const momentDate = moment(date);
    const {weekday} = options;
    return momentDate.format(weekday ? 'ddd' : 'D');
};
const formatTimeScaleDate = date => moment(date).format('HH:mm');

const DayScaleCell = ((
    {formatDate, ...restProps},
) => (
    <StyledWeekViewDayScaleCell
        {...restProps}
        formatDate={formatDayScaleDate}
        className={classes.dayScaleCell}
    />
));

const TimeScaleLabel = (
    {formatDate, ...restProps},
) => <WeekView.TimeScaleLabel {...restProps} formatDate={formatTimeScaleDate}/>;

const STATUS_COLORS = {
    'accepted': 'green',
    'needs_action': 'gray',
    'declined': '#8B0000',
    'tentative': '#FFD700'
};

const ParticipantStatus = styled(Box)`
    height: 15px;
    width: 15px;
    background-color: ${props => STATUS_COLORS[props.status]};
    border-radius: 50%;
    margin-right: .6rem;
`;

const ParticipantList = styled(List)`
    padding: 0 !important;
`;

const ParticipantItem = styled(ListItem)`
    padding: 0 !important;
`;

const TextEditor = (props) => {
    if (props.type === 'multilineTextEditor') {
        return null;
    }
    return <AppointmentForm.TextEditor {...props} />;
};

const BooleanEditor = (props) => {
    if (props.label === 'Repeat')
        return null;
    return <AppointmentForm.BooleanEditor {...props} />;
};

const ResourceEditorComponent = (_props) => {
    return <></>;
};

const CommandLayoutComponent = (props) => {
    return <AppointmentForm.CommandLayout {...props} hideDeleteButton={true}></AppointmentForm.CommandLayout>;
}

const BasicLayout = ({onFieldChange, appointmentData, ...restProps}) => {
    const onSummaryChange = (nextValue) => {
        onFieldChange({summary: nextValue});
    };
    const handleChange = (event, newValues) => {
        const mappedNewValues = newValues.map(e => typeof (e) == 'string' ? {email: e} : e);
        const initialValues = appointmentData.participants;
        const added = mappedNewValues.filter(elem => initialValues.every(e => e.email !== elem.email));
        const removed = initialValues.filter(elem => mappedNewValues.every(e => e.email !== elem.email));
        onFieldChange({
            attendees: {
                invite: added,
                remove: removed,
            }
        });
    };

    return (
        <AppointmentForm.BasicLayout
            appointmentData={appointmentData}
            onFieldChange={onFieldChange}
            {...restProps}
        >

            <Box
                sx={{marginTop: '2rem'}}>
                <Autocomplete
                    multiple
                    options={appointmentData.participants}
                    defaultValue={appointmentData.participants}
                    getOptionLabel={option => option.email}
                    freeSolo
                    onChange={handleChange}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip variant="outlined"
                                  label={typeof (option) == 'string' ? option : option.email} {...getTagProps({index})} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Attendees"
                            placeholder="Email"
                        />
                    )}
                />
            </Box>

            <Box
                sx={{marginTop: '1rem'}}>
                <AppointmentForm.TextEditor
                    value={appointmentData.summary}
                    onValueChange={onSummaryChange}
                    label="Summary"
                    multiline
                />
            </Box>
        </AppointmentForm.BasicLayout>
    );
};

class Demo extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: props.events ? this.appointmentsData(props.events) : [],
            pending: true,
            accepted: true,
            declined: true,
            tentative: true,
            meetings: true,
            calls: true,
            currentViewName: 'Week',
            openEventModal: false,
            modalData: {},
            eventToUpdate: null,
            jobEmail: null,
        };

        this.commitChanges = this.commitChanges.bind(this);
    }

    componentDidMount() {
        this.handleAddOrUpdateEvent();
        this.props.resetCalendarLoader();
    }

    componentDidUpdate(prevProps, _prevState) {
        const {events} = this.props;

        if (prevProps.events !== events) {
            this.setState({
                data: this.appointmentsData(events)
            });
            if (this.state.accepted ||
                this.state.pending ||
                this.state.tentative ||
                this.state.declined ||
                this.state.meetings ||
                this.state.calls
            )

                this.handleFilter(this.state);
        }

        this.handleAddOrUpdateEvent();
    }

    handleAddOrUpdateEvent() {
        const {events} = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        const opportunityId = urlParams.get('opportunity_id');

        if (type == 'add_update_event' && opportunityId) {
            const _eventToUpdate = events?.filter(e => e.event_id?.split('__').length === 2 && e.event_id?.split('__')[1] == opportunityId)
                .sort((a, b) => new Date(b.created) - new Date(a.created)).at(0);

            const _jobEmail = Object.values(opportunity.related_objects.jobs).filter(item => item.job_is_primary == 1);

            this.setState({ eventToUpdate: _eventToUpdate, openEventModal: true, jobEmail: _jobEmail });
            urlParams.delete('type');
            urlParams.delete('opportunity_id');

            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${urlParams.toString()}`;
            window.history.replaceState({}, document.title, newUrl);
        }
    }

    handleAddEventModalClose(formSubmitted = false) {
        const showEmailModal = formSubmitted && !!this.state.eventToUpdate;
        this.setState({openEventModal: false, eventToUpdate: null, openSendEmailModal: showEmailModal});
    };

    currentViewNameChange = (e) => {
        this.setState({currentViewName: e});
    };

    handleFilter = ({ accepted, pending, declined, tentative, meetings, calls }) => {

        const list = this.appointmentsData(this.props.events);
        const filteredList = list.filter((item) => {
            if (accepted && item.event_status_overall == 'Accepted') {
                return true;
            }
            if (declined && item.event_status_overall == 'Rejected') {
                return true;
            }

            if (pending && item.event_status_overall == 'Pending')
                return true;

            if (tentative && item.event_status_overall == 'Tentative')
                return true;

            if (item.event_status_overall == undefined && accepted && pending && declined && tentative && meetings && calls)
                return true;

            return false;
        }).filter(item => {
            if (meetings && item.event_type == 'phone_meeting')
                return true;

            if (calls && item.event_type == 'follow_up')
                return true;

            if (meetings && calls)
                return true;

            return false;
        });

        this.setState({data: filteredList});
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.checked},
            () => this.handleFilter(this.state));
    };

    appointmentsData = (apiResData) => {
        const erpDataArray = []
        if (!apiResData) {
            return erpDataArray;
        }

        const synced_action_ids = {};
        apiResData.map((item) => {
            let action_id = null;
            if (item.event_id) {
                action_id = item.event_id.split('__')[0];
                synced_action_ids[action_id] = true;
            }

            var tempObj = {}

            if (action_id && Array.isArray(this.props.legacy_events)) {
                const legacyEvent = this.props.legacy_events?.find(e => e.opportunity_action_id == action_id);
                tempObj['event_type'] = legacyEvent ? legacyEvent.opportunity_action_type : "";
            }

            tempObj['id'] = item.event_uid || item.event_id
            tempObj['id_type'] = item.event_uid ? 'event_uid' : 'event_id'
            tempObj['event_id'] = item.event_id
            tempObj['opportunity_id'] = item.event_id?.includes('__') ? item.event_id.split('__')[1] : null
            tempObj['calendar_id'] = item.calendar_id
            const startTime = new Date(item.start);
            const endTime = new Date(item.end);
            tempObj['startDate'] = startTime
            tempObj['endDate'] = endTime
            tempObj['participants'] = item.attendees.filter(e => e.email !== this.props.user.user_details.user_email)
            tempObj['title'] = `G- ${item.summary}`
            tempObj['summary'] = item.description
            tempObj['participation_status'] = item.participation_status
            const all_accepted = item.all_accepted;
            const status_rejected = item.attendees.some(e => e.status == 'declined');
            const status_tentative = item.attendees.some(e => e.status == 'tentative');

            if (all_accepted)
                tempObj['event_status_overall'] = 'Accepted';
            else if (status_rejected)
                tempObj['event_status_overall'] = 'Rejected';
            else if (status_tentative)
                tempObj['event_status_overall'] = 'Tentative'
            else
                tempObj['event_status_overall'] = 'Pending';

            erpDataArray.push({...item, ...tempObj})
        })

        if (Array.isArray(this.props.legacy_events)) {
            this.props.legacy_events?.map((item, index) => {
                if (synced_action_ids[item.opportunity_action_id])
                    return;

                var tempObj = {}
                tempObj['id'] = index
                let sDate = item.opportunity_action_due_time
                tempObj['startDate'] = new Date(sDate.slice(0, 4), parseInt(sDate.slice(5, 7) - 1), sDate.slice(8, 10), sDate.slice(11, 13), sDate.slice(14, 16), sDate.slice(17, 19))
                tempObj['endDate'] = new Date(sDate.slice(0, 4), parseInt(sDate.slice(5, 7) - 1), sDate.slice(8, 10), sDate.slice(11, 13), sDate.slice(14, 16), sDate.slice(17, 19))
                tempObj['title'] = item.person_name
                tempObj['opportunity_id'] = item.opportunity_id
                if (item.opportunity_action_type === 'phone_meeting') {
                    tempObj['location'] = "Meetings"
                }
                if (item.opportunity_action_type === 'follow_up') {
                    tempObj['location'] = "Calls"
                }
                tempObj['event_type'] = item.opportunity_action_type;
                let eDate = new Date(tempObj['startDate']);
                tempObj['endDate'].setMinutes(eDate.getMinutes() + 15)

                erpDataArray.push({...item, ...tempObj});
            });
        }

        return erpDataArray
    }

    handleCellClick = (data) => {
        const { events, opportunity } = this.props;

        const _eventToUpdate = events?.filter(e => e.event_id?.split('__').length === 2 && e.event_id?.split('__')[1] == opportunity?.main?.opportunity_id)
                                .sort((a, b) => new Date(b.created) - new Date(a.created)).at(0);
        const _jobEmail = Object.values(opportunity.related_objects.jobs).filter(item => item.job_is_primary == 1);

        this.setState({ openEventModal: !this.state.openEventModal, modalData: data, eventToUpdate: _eventToUpdate, jobEmail: _jobEmail });
    }

    WeekTimeTableCell = props => (
        <WeekView.TimeTableCell
            {...props}
            onDoubleClick={() => this.props.isClickable && this.handleCellClick(props)}
            onClick={() => this.props.isClickable && this.handleCellClick(props)}
        />
    );

    MonthTimeTableCell = props => (
        <MonthView.TimeTableCell
            {...props}
            onDoubleClick={() => this.props.isClickable && this.handleCellClick(props)}
            onClick={() => this.props.isClickable && this.handleCellClick(props)}
        />
    );

    DayTimeTableCell = props => (
        <DayView.TimeTableCell
            {...props}
            onDoubleClick={() => this.props.isClickable && this.handleCellClick(props)}
            onClick={() => this.props.isClickable && this.handleCellClick(props)}
        />
    );

    commitChanges = (event) => {
        if (event.changed) {
            const id = Object.keys(event.changed)[0];
            const index = this.state.data.findIndex(e => e.id == id);
            const previousEventData = {...this.state.data[index]};
            const updatedEventData = event.changed[id];
            delete previousEventData['attendees'];

            const event_data = {...previousEventData, ...updatedEventData};
            event_data[event_data.id_type] = event_data['id'];
            event_data['description'] = event_data['summary'];
            event_data['summary'] = event_data['title'];
            event_data['start'] = new Date(event_data['startDate']);
            event_data['end'] = new Date(event_data['endDate']);

            if (event_data['start'] >= event_data['end'])
                return alert(`Start time can not be than end time.`);

            if (updatedEventData.allDay) {
                event_data['start'].setHours(8, 0, 0);
                event_data['end'].setHours(20, 0, 0);
            }
            event_data['start'] = event_data['start'].toISOString();
            event_data['end'] = event_data['end'].toISOString();

            this.props.updateEvent(event_data);
        }
    }

    handleAppointmentFormOpening = (e) => {
    }

    render() {
        const {data, currentViewName} = this.state;
        const {opportunity} = this.props;

        const AppointmentHeaderComponent = (props) => {
            const opportunity_id = props.appointmentData.opportunity_id;
            const handleGotoClick = () => {
                const {navigate} = this.props;
                navigate(`/opportunities/id/${opportunity_id}?tab=history`);
            };
            return <AppointmentTooltip.Header {...props}>
                {opportunity_id &&
                    <Button onClick={handleGotoClick}>Go to opportunity &gt;</Button>
                }
            </AppointmentTooltip.Header>;
        }

        const AppointmentContent = ({
                                        children,
                                        appointmentData,
                                        ...restProps
                                    }) => {
            let participants = appointmentData.participants ? [...appointmentData.participants] : [];
            if (appointmentData.organizer)
                participants = participants.filter(p => p.email !== appointmentData.organizer.email);

            return (
                <AppointmentTooltip.Content
                    {...restProps}
                    appointmentData={appointmentData}
                >

                    {participants?.length > 0 &&
                        <Container>
                            <Typography variant="h6">
                                <b>Participants</b>
                            </Typography>
                            <ParticipantList>
                                {participants.map((participant, index) => (
                                    <ParticipantItem key={index}>
                                        <ParticipantStatus status={participant.status}/>
                                        <ListItemText primary={participant.email}/>
                                    </ParticipantItem>
                                ))}
                            </ParticipantList>
                        </Container>
                    }
                </AppointmentTooltip.Content>
            )
        };

        const resources = [{
            fieldName: 'event_status_overall',
            title: 'Participation Status',
            instances: [
                {id: 'Accepted', text: 'Accepted', color: STATUS_COLORS.accepted},
                {id: 'Pending', text: 'Pending', color: STATUS_COLORS.needs_action},
                {id: 'Rejected', text: 'Rejected', color: STATUS_COLORS.declined},
                {id: 'Tentative', text: 'Tentative', color: STATUS_COLORS.tentative},
            ]
        }];

        return (
            <>
                {(this.props.cronofyAuthorized && this.props.getAllEvents_loading) || this.props.calendarIsLoading ?
                    <ShowProgress/> :
                    <>
                        <Paper className={currentViewName.toLowerCase()}>

                            <Scheduler
                                data={data}
                                height={660}
                            >
                                <ViewState
                                    defaultCurrentDate={formatDate(new Date())}
                                    currentViewName={currentViewName}
                                />

                                <EditingState
                                    onCommitChanges={this.commitChanges}
                                />
                                <IntegratedEditing/>

                                <DayView
                                    startDayHour={8}
                                    endDayHour={20}
                                    timeTableCellComponent={this.DayTimeTableCell}
                                    dayScaleCellComponent={DayScaleCell}
                                    timeScaleLabelComponent={TimeScaleLabel}
                                />
                                <WeekView
                                    name="Week"
                                    displayName="Week"
                                    excludedDays={[0, 6]}
                                    startDayHour={8}
                                    endDayHour={20}
                                    timeTableCellComponent={this.WeekTimeTableCell}
                                    dayScaleCellComponent={DayScaleCell}
                                    timeScaleLabelComponent={TimeScaleLabel}
                                />
                                <MonthView
                                    timeTableCellComponent={this.MonthTimeTableCell}
                                    startDayHour={8}
                                    endDayHour={20}
                                />

                                <Toolbar/>
                                <DateNavigator/>
                                <TodayButton/>

                                <ConfirmationDialog/>
                                <Appointments/>
                                <AppointmentTooltip
                                    showCloseButton
                                    headerComponent={AppointmentHeaderComponent}
                                    contentComponent={AppointmentContent}
                                />
                                <AppointmentForm
                                    commandLayoutComponent={CommandLayoutComponent}
                                    basicLayoutComponent={BasicLayout}
                                    textEditorComponent={TextEditor}
                                    booleanEditorComponent={BooleanEditor}
                                    resourceEditorComponent={ResourceEditorComponent}
                                />
                                <IntegratedEditing/>

                                <Resources data={resources}/>
                                <div style={{display: 'flex', justifyContent: 'space-between', paddingTop: '12px'}}>
                                    <div style={{marginLeft: '33px', textAlign: 'left'}}>
                                        <FormControlLabel
                                            label="Accepted"
                                            control={<Checkbox
                                                checked={this.state.accepted}
                                                onChange={this.handleChange}
                                                name={'accepted'}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                        />
                                        <FormControlLabel
                                            label="Pending"
                                            control={<Checkbox
                                                checked={this.state.pending}
                                                onChange={this.handleChange}
                                                name={'pending'}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                        />
                                        <FormControlLabel
                                            label="Rejected"
                                            control={<Checkbox
                                                checked={this.state.declined}
                                                onChange={this.handleChange}
                                                name={'declined'}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                        />
                                        <FormControlLabel
                                            label="Tentative"
                                            control={<Checkbox
                                                checked={this.state.tentative}
                                                onChange={this.handleChange}
                                                name={'tentative'}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                        />

                                        <FormControlLabel
                                            label="Phone Calls"
                                            control={<Checkbox
                                                checked={this.state.calls}
                                                onChange={this.handleChange}
                                                name={'calls'}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                        />

                                        <FormControlLabel
                                            label="Meetings"
                                            control={<Checkbox
                                                checked={this.state.meetings}
                                                onChange={this.handleChange}
                                                name={'meetings'}
                                                inputProps={{'aria-label': 'controlled'}}
                                            />}
                                        />
                                    </div>
                                    <ExternalViewSwitcher
                                        currentViewName={currentViewName}
                                        onChange={this.currentViewNameChange}
                                    />
                                </div>
                            </Scheduler>

                        </Paper>
                        <p><GoogleIcon style={{fontSize: '14px'}}/> - Indicates google synchronized events.</p>
                    </>}
                <SendEmailModal
                    opportunity={opportunity}
                    handleClose={() => this.setState({openSendEmailModal: false})}
                    open={this.state.openSendEmailModal}
                />
                {(this.state.openEventModal) && <AddEventModal
                    open={this.state.openEventModal}
                    currentEvent={this.state.eventToUpdate}
                    tab={this.props.tab}
                    handleClose={this.handleAddEventModalClose.bind(this)}
                    startDate={this.state.modalData.startDate}
                    endDate={this.state.modalData.endDate}
                    opportunity={opportunity}
                    handleTabChange={this.props.handleTabChange}
                    jobEmail={this.state.jobEmail}
                    />
                }
            </>
        );
    }
}

function WithNavigate(props) {
    let params = useParams();
    let navigate = useNavigate();
    return <Demo {...props} params={params} navigate={navigate}/>
}

const mapStateToProps = (state) => ({
    user: accountSelectors.user(state),
    isLoading: opportunitySelectors.isLoading(state),
    calendar: opportunitySelectors.calendar(state),
    events: opportunitySelectors.events(state),
    getAllEvents_loading: opportunitySelectors.getAllEvents_loading(state),
    getCalendar_loading: opportunitySelectors.getCalendar_loading(state),
    getAllEvenets_inProgress: opportunitySelectors.getAllEvenets_inProgress(state),
    cronofyAuthorized: accountSelectors.cronofyAuthorized(state),
    calendarIsLoading: opportunitySelectors.calendarIsLoading(state),
    opportunity: opportunitySelectors.opportunity(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        updateEvent: opportunityActions.updateEvent,
        resetCalendarLoader: opportunityActions.resetCalendarLoader,
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WithNavigate);

const ExternalViewSwitcher = ({
                                  currentViewName,
                                  onChange,
                              }) => (
    <div style={{width: '400px'}}>
        <Button style={{marginRight: '12px'}} onClick={() => onChange('Day')}
                variant={currentViewName === 'Day' ? 'contained' : 'text'} name={'Day'}>Day</Button>
        <Button onClick={() => onChange('Week')} variant={currentViewName === 'Week' ? 'contained' : 'text'}
                name={'Week'}>Week</Button>
        <Button style={{marginLeft: '12px'}} onClick={() => onChange('Month')}
                variant={currentViewName === 'Month' ? 'contained' : 'text'} name={'Month'}>Month</Button>
    </div>
);

function formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
}