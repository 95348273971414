import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga/effects';
import Swal from "sweetalert2";
import { types as eventsOverviewTypes, actions as eventsOverviewActions } from '../Ducks/eventsOverview';

import { callApi } from './utils';
import * as api from '../API/eventsOverview';


function* getEventsOverview(action) {
  const { success, failure } = eventsOverviewActions.saga.getEventsOverview;

  yield callApi(
    call(api.getEventsOverview, action.data),
    success,
    failure
  )
}

function* updateAttendanceStatus(action) {
  const { success, failure } = eventsOverviewActions.saga.updateAttendanceStatus;

  function* postApiSuccessCallEffect() {
    yield call(getParticipantDetails, { ...action, data: { event_id: action.data.participantDetails.event_id, participant_id: action.data.participantDetails.participant_id } });
    yield call(getEventsOverview, { ...action, data: action.data.query });
  }

  yield callApi(
    call(api.updateAttendanceStatus, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updateSendoutEmail(action) {
  const { success, failure } = eventsOverviewActions.saga.updateSendoutEmail;

  function* postApiSuccessCallEffect() {
    yield call(getEventsOverview, { ...action, data: action.data.query });
  }

  yield callApi(
    call(api.updateSendoutEmail, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getPersonPurchases(action) {
  const { success, failure } = eventsOverviewActions.saga.getPersonPurchases;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getPersonPurchases, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getPersonOpportunities(action) {
  const { success, failure } = eventsOverviewActions.saga.getPersonOpportunities;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getPersonOpportunities, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getPersonOpenOpportunity(action) {
  const { success, failure } = eventsOverviewActions.saga.getPersonOpenOpportunity;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getPersonOpenOpportunity, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updatePersonStage(action) {
  const { success, failure } = eventsOverviewActions.saga.updatePersonStage;

  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Done!',
      showConfirmButton: false,
      timer: 1500
    })
  }

  yield callApi(
    call(api.updatePersonStage, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getPersonHistory(action) {
  const { success, failure } = eventsOverviewActions.saga.getPersonHistory;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getPersonHistory, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getNoteTypes(action) {
  const { success, failure } = eventsOverviewActions.saga.getNoteTypes;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getNoteTypes, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* addHistoryLog(action) {
  const { success, failure } = eventsOverviewActions.saga.addHistoryLog;

  function* postApiSuccessCallEffect() {
    yield call(getPersonHistory, { ...action, data: { person_id: action.data.id, query: action.data.type } });
  }

  yield callApi(
    call(api.addHistoryLog, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getParticipantDetails(action) {
  const { success, failure } = eventsOverviewActions.saga.getParticipantDetails;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getParticipantDetails, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getEventDetails(action) {
  const { success, failure } = eventsOverviewActions.saga.getEventDetails;

  function* postApiSuccessCallEffect() {

  }

  yield callApi(
    call(api.getEventDetails, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updateNotes(action) {
  const { success, failure } = eventsOverviewActions.saga.updateNotes;

  function* postApiSuccessCallEffect() {
    yield call(getEventDetails, { ...action, data: action.data.event_id });
  }

  yield callApi(
    call(api.updateNotes, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updateEmail(action) {
  const { success, failure } = eventsOverviewActions.saga.updateEmail;

  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Done!',
      showConfirmButton: false,
      timer: 1500
    })
    action.data.setSubmitting(false);
    yield call(getEventDetails, { ...action, data: action.data.event_id });
  }

  yield callApi(
    call(api.updateEmail, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* addPlannedAction(action) {
  const { success, failure } = eventsOverviewActions.saga.addPlannedAction;

  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Done!',
      showConfirmButton: false,
      timer: 1500
    })
    yield call(getPersonOpenOpportunity, { ...action, data: action.data.person_id });
  }

  yield callApi(
    call(api.addPlannedAction, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* editPlannedAction(action) {
  const { success, failure } = eventsOverviewActions.saga.editPlannedAction;

  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Done!',
      showConfirmButton: false,
      timer: 1500
    })
    yield call(getPersonOpenOpportunity, { ...action, data: action.data.person_id });
  }

  yield callApi(
    call(api.editPlannedAction, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* deleteAttachedFile(action) {
  const { success, failure } = eventsOverviewActions.saga.deleteAttachedFile;

  function* postApiSuccessCallEffect() {
    yield call(getEventDetails, { ...action, data: action.data.event_id });
  }

  yield callApi(
    call(api.deleteAttachedFile, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getNetworkDirectors(action) {
  const { success, failure } = eventsOverviewActions.saga.getNetworkDirectors;

  yield callApi(
    call(api.getNetworkDirectors, action.data),
    success,
    failure
  )
}

function* getEventsOverviewUsers(action) {
  const { success, failure } = eventsOverviewActions.saga.getEventsOverviewUsers;

  yield callApi(
    call(api.getEventsOverviewUsers, action.data),
    success,
    failure
  )
}

function* pauseCase(action) {
  const { success, failure } = eventsOverviewActions.saga.pauseCase;

  function* postApiSuccessCallEffect(res) {
    yield call(getParticipantDetails, { ...action, data: { event_id: action.data.event_id, participant_id: action.data.participant_id } });
    yield call(getEventsOverview, { ...action, data: action.data.query });
    action.data.toggleSpecialCaseTab(false);
  }

  yield callApi(
    call(api.pauseCase, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* bookMeeting(action) {
  const { success, failure } = eventsOverviewActions.saga.bookMeeting;

  function* postApiSuccessCallEffect(res) {
    Swal.fire({
      icon: 'success',
      title: 'Done!',
      showConfirmButton: false,
      timer: 1500
    });

    yield call(getPersonMeetings, { ...action, data: action.data.purchase_id });
    yield call(getParticipantDetails, { ...action, data: { event_id: action.data.event_id, participant_id: action.data.participant_id } });
    yield call(getEventsOverview, { ...action, data: action.data.query });
    yield call(getResaleOverview, { ...action, data: action.data.query });
  }

  yield callApi(
    call(api.bookMeeting, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* registerOnGoingCase(action) {
  const { success, failure } = eventsOverviewActions.saga.registerOnGoingCase;

  function* postApiSuccessCallEffect(res) {
    if (res.case_id) {
      yield call(getEventsOverview, { ...action, data: action.data.query });
      yield call(getPersonPlannedCalls, { ...action, data: action.data.person_id });
      action.data.toggleSpecialCaseTab(false);
    }
    if (res.case_id && action.data.history_log_data) {
      yield call(addHistoryLog, { ...action, data: { id: action.data.person_id, type: action.data.type, obj: action.data.history_log_data } });
    }
  }

  yield callApi(
    call(api.registerOnGoingCase, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getPlannedCallTypes(action) {
  const { success, failure } = eventsOverviewActions.saga.getPlannedCallTypes;

  yield callApi(
    call(api.getPlannedCallTypes, action.data),
    success,
    failure
  )
}

function* getPersonPlannedCalls(action) {
  const { success, failure } = eventsOverviewActions.saga.getPersonPlannedCalls;

  yield callApi(
    call(api.getPersonPlannedCalls, action.data),
    success,
    failure
  )
}

function* getPlannedCall(action) {
  const { success, failure } = eventsOverviewActions.saga.getPlannedCall;

  yield callApi(
    call(api.getPlannedCall, action.data),
    success,
    failure
  )
}

function* getSpecialCaseDetails(action) {
  const { success, failure } = eventsOverviewActions.saga.getSpecialCaseDetails;

  yield callApi(
    call(api.getSpecialCaseDetails, action.data),
    success,
    failure
  )
}


function* createPlannedCall(action) {
  const { success, failure } = eventsOverviewActions.saga.createPlannedCall;

  function* postApiSuccessCallEffect() {
    yield call(getPersonPlannedCalls, { ...action, data: action.data.person_id });
  }

  yield callApi(
    call(api.createPlannedCall, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updatePlannedCall(action) {
  const { success, failure } = eventsOverviewActions.saga.updatePlannedCall;

  function* postApiSuccessCallEffect() {
    yield call(getPersonPlannedCalls, { ...action, data: action.data.person_id });
  }

  yield callApi(
    call(api.updatePlannedCall, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* updateBookedMeeting(action) {
  const { success, failure } = eventsOverviewActions.saga.updateBookedMeeting;

  function* postApiSuccessCallEffect() {
    if (action.data.cancelled) {
      yield call(getPersonOpenOpportunity, { ...action, data: action.data.person_id });
    } else {
      yield call(getEventsOverview, { ...action, data: action.data.query });
      yield call(getResaleOverview, { ...action, data: action.data.query });
    }
  }

  yield callApi(
    call(api.updateBookedMeeting, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* getBookedMeetingById(action) {
  const { success, failure } = eventsOverviewActions.saga.getBookedMeetingById;

  yield callApi(
    call(api.getBookedMeetingById, action.data),
    success,
    failure
  )
}

function* getPersonMeetings(action) {
  const { success, failure } = eventsOverviewActions.saga.getPersonMeetings;

  yield callApi(
    call(api.getPersonMeetings, action.data),
    success,
    failure
  )
}

function* getCasesOverview(action) {
  const { success, failure } = eventsOverviewActions.saga.getCasesOverview;

  yield callApi(
    call(api.getCasesOverview, action.data),
    success,
    failure
  )
}

function* getResaleOverview(action) {
  const { success, failure } = eventsOverviewActions.saga.getResaleOverview;

  yield callApi(
    call(api.getResaleOverview, action.data),
    success,
    failure
  )
}

function* getPendingCases(action) {
  const { success, failure } = eventsOverviewActions.saga.getPendingCases;

  yield callApi(
    call(api.getPendingCases, action.data),
    success,
    failure
  )
}

function* getCaseUsersList(action) {
  const { success, failure } = eventsOverviewActions.saga.getCaseUsersList;

  yield callApi(
    call(api.getCaseUsersList, action.data),
    success,
    failure
  )
}

function* getEventMembers(action) {
  const { success, failure } = eventsOverviewActions.saga.getEventMembers;

  yield callApi(
    call(api.getEventMembers, action.data),
    success,
    failure
  )
}

function* resolveCase(action) {
  const { success, failure } = eventsOverviewActions.saga.resolveCase;

  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Case resolved successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    action.data.handleCaseClose();
    yield call(getPendingCases, { ...action, data: {} });
  }

  yield callApi(
    call(api.resolveCase, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}

function* caseDecline(action) {
  const { success, failure } = eventsOverviewActions.saga.caseDecline;
  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Case Decline successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    action.data.handleCaseClose();
    yield call(getCasesOverview, { ...action, data: action.data.query });
    yield call(getPendingCases, { ...action, data: {} });
  }
  yield callApi(
    call(api.caseDecline, action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )
}
function* getCasesEventsOverview(action) {
  const { success, failure } = eventsOverviewActions.saga.getCasesEventsOverview;
  yield callApi(
    call(api.getCasesEventsOverview,action.data),
    success,
    failure
  )
}

function* updateAssignee(action) 
{
  function* postApiSuccessCallEffect() {
    Swal.fire({
      icon: 'success',
      title: 'Case Reassigned successfully!',
      showConfirmButton: false,
      timer: 1500
    })
    yield call(getSpecialCaseDetails, { ...action, data: action.data.purchaseId });
    yield call(getCasesOverview, action.data);
  }
  const { success, failure } = eventsOverviewActions.saga.updateAssignee;
  yield callApi(
    call(api.updateAssignee,action.data),
    success,
    failure,
    { postApiSuccessCallEffect }
  )

}

export default function* rootCustomerSaga() {
  yield takeLatest(eventsOverviewTypes.getEventsOverview, getEventsOverview);
  yield takeLatest(eventsOverviewTypes.updateAttendanceStatus, updateAttendanceStatus);
  yield takeLatest(eventsOverviewTypes.updateSendoutEmail, updateSendoutEmail);
  yield takeLatest(eventsOverviewTypes.getPersonPurchases, getPersonPurchases);
  yield takeLatest(eventsOverviewTypes.getPersonOpportunities, getPersonOpportunities);
  yield takeLatest(eventsOverviewTypes.getPersonOpenOpportunity, getPersonOpenOpportunity);
  yield takeLatest(eventsOverviewTypes.getPersonHistory, getPersonHistory);
  yield takeLatest(eventsOverviewTypes.getParticipantDetails, getParticipantDetails);
  yield takeLatest(eventsOverviewTypes.getEventDetails, getEventDetails);
  yield takeLatest(eventsOverviewTypes.getNoteTypes, getNoteTypes);
  yield takeLatest(eventsOverviewTypes.addHistoryLog, addHistoryLog);
  yield takeLatest(eventsOverviewTypes.updateNotes, updateNotes);
  yield takeLatest(eventsOverviewTypes.updateEmail, updateEmail);
  yield takeLatest(eventsOverviewTypes.getNetworkDirectors, getNetworkDirectors);
  yield takeLatest(eventsOverviewTypes.getEventsOverviewUsers, getEventsOverviewUsers);
  yield takeLatest(eventsOverviewTypes.deleteAttachedFile, deleteAttachedFile);
  yield takeLatest(eventsOverviewTypes.updatePersonStage, updatePersonStage);
  yield takeLatest(eventsOverviewTypes.addPlannedAction, addPlannedAction);
  yield takeLatest(eventsOverviewTypes.editPlannedAction, editPlannedAction);
  yield takeLatest(eventsOverviewTypes.pauseCase, pauseCase);
  yield takeLatest(eventsOverviewTypes.registerOnGoingCase, registerOnGoingCase);
  yield takeLatest(eventsOverviewTypes.bookMeeting, bookMeeting);
  yield takeLatest(eventsOverviewTypes.getPlannedCallTypes, getPlannedCallTypes);
  yield takeLatest(eventsOverviewTypes.getPersonPlannedCalls, getPersonPlannedCalls);
  yield takeLatest(eventsOverviewTypes.createPlannedCall, createPlannedCall);
  yield takeLatest(eventsOverviewTypes.updatePlannedCall, updatePlannedCall);
  yield takeLatest(eventsOverviewTypes.getPlannedCall, getPlannedCall);
  yield takeLatest(eventsOverviewTypes.getSpecialCaseDetails, getSpecialCaseDetails);
  yield takeLatest(eventsOverviewTypes.getBookedMeetingById, getBookedMeetingById);
  yield takeLatest(eventsOverviewTypes.updateBookedMeeting, updateBookedMeeting);
  yield takeLatest(eventsOverviewTypes.getPersonMeetings, getPersonMeetings);
  yield takeLatest(eventsOverviewTypes.getCasesOverview, getCasesOverview);
  yield takeLatest(eventsOverviewTypes.getResaleOverview, getResaleOverview);
  yield takeLatest(eventsOverviewTypes.resolveCase, resolveCase);
  yield takeLatest(eventsOverviewTypes.getPendingCases, getPendingCases);
  yield takeLatest(eventsOverviewTypes.getCaseUsersList, getCaseUsersList);
  yield takeLatest(eventsOverviewTypes.getEventMembers, getEventMembers);
  yield takeLatest(eventsOverviewTypes.caseDecline,caseDecline);
  yield takeLatest(eventsOverviewTypes.getCasesEventsOverview, getCasesEventsOverview);
  yield takeLatest(eventsOverviewTypes.updateAssignee,updateAssignee);
}