import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as opportunitySelectors, actions as opportunityActions } from '../../../../Ducks/opportunity';
import { selectors as contactSelectors, actions as contactActions } from '../../../../Ducks/contacts';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Button, Checkbox, DialogActions, DialogContent, DialogContentText, FormControl, FormControlLabel, FormLabel, Grid, IconButton, List, ListItem, ListItemText, Radio, RadioGroup } from '@mui/material';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowRightAlt, Bloodtype } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const useStyles = theme => ({
    flex: {
        display: 'flex',
        justifyContent: 'center'
    },
    button: {
        fontSize: '11px'
    },
    title: {
        fontWeight: '600'
    }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(4),
    },
    '.MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0
    },
    '.MuiListItem-root .MuiTypography-root': {
        fontSize: '15px'
    },
    '.MuiTypography-h6': {
        paddingTop: '0px',
        paddingBottom: 0,
        textAlign: 'center'
    },
    'hr': {
        borderBottom: '1px solid #d7d7d7',
        width: '90%'
    },
    '.MuiGrid-container': {
        borderBottom: '1px solid #d7d7d7'
    },
    'h5': {
        fontSize: '14px',
        textTransform: 'capitalize'
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const FormItem = ({ title, value,food }) => {
    const formatTitle = () => {
        if (title === 'company_name') {
            let arr = title.split('_');
            return arr.join(" ");
        }
        else {
            let arr = title.split('_');
            arr.splice(0, 1);
            if (arr.length > 1) {
                return arr.join(" ");
            }
            else {
                return arr
            }
        }

    }

    return (
        <Grid container sx={{ marginBottom: '15px' }} spacing={1}>
            <Grid sx={{ textAlign: 'center' }} item xs={4}>
                <Typography component="h5" variant="h5">
                    <b>{formatTitle()}</b>
                </Typography>
            </Grid>
            <Grid sx={{ textAlign: 'center' }} item xs={4}>
                <ArrowRightAlt />
            </Grid>
            <Grid sx={{ textAlign: 'center' }} item xs={4}>
                <Typography component="span">
                    {
                        title === 'person_food_considerations_1' ? food.type1_food.filter(item => item.id == value)[0]?.title :
                        title === 'person_food_considerations_2' ? food.type2_food.filter(item => value?.includes(item.id))?.map(item => item.title) : 
                        value
                    }
                </Typography>
            </Grid>
        </Grid>
    )
}

const BorderElement = ({ gap }) => {
    return (
        <div className='border-element' style={{ margin: '0 auto', width: '90%', paddingBottom: gap, borderBottom: '1px solid #d7d7d7' }} />
    )
}


const WonModal = (props) => {
    const { onClose, selectedValue, open, opportunity, classes, formData,closeWonLoading } = props;
    const [radioOptions, setRadioOptions] = React.useState([]);


    const showProgress = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
        </div>
    )

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        // onClose(value);
    };

    const handleConfirm = () => {
        props.postClosedWon({ formData, id: props.params.id, userStageId: opportunity.main.pipeline_stage_id, opportunityId: opportunity.main.opportunityId  });
        handleClose();
    }

    return (
        <BootstrapDialog onClose={handleClose} open={open}>
            <BorderElement gap="50px" />
            <BootstrapDialogTitle>Are you sure you want to close this opportunity as <b>closed:won</b></BootstrapDialogTitle>
            <BootstrapDialogTitle>If so confirm that the information below is correct</BootstrapDialogTitle>
            <BorderElement gap="10px" />
            <DialogContent>
                {
                    Object.keys(formData).filter((key, index) => {
                        if (
                            key === "opportunity_address_1" ||
                            key === "opportunity_food_considerations"
                        ) {
                            return false; // skip
                        }
                        return true;
                    }).map((key, index) => {
                        if(formData[key] !== "") {
                            return <FormItem title={key} key={index} value={formData[key]} food={props.foodConsideration}/>
                        }
                    })
                }
            </DialogContent>
            <DialogActions className={classes.flex}>
                <Button className={classes.button} variant="contained" color='inherit' onClick={handleConfirm} disabled={closeWonLoading}>Confirm</Button>
                <Button className={classes.button} variant="contained" color="error" onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}

const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
    lostReasons: opportunitySelectors.lostReasons(state),
    opportunity: opportunitySelectors.opportunity(state),
    closeWonLoading: opportunitySelectors.isLoading(state),
    foodConsideration: contactSelectors.foodConsiderations(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        postClosedWon: opportunityActions.postClosedWon
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <WonModal {...props} params={params} />
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));